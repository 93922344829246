import { Text } from '@upstox/upstox-ui';
import React, { FunctionComponent, useContext, useState } from 'react';
import {
  SEBI_SCORE_URL,
  UPSTOX_FORM_URL,
  PRIVACY_POLICY_URL,
  UPSTOX_BASE_URL,
  DOWNLOAD_UPSTOX_URL,
  UPSTOX_PRODUCTS_URL,
  PRO_WEB_URL,
  PRO_MOBILE_URL,
} from '../../Models/Actions/Footer/url-helper';

const About: FunctionComponent = () => {
  const [readMore, setReadMore] = useState(false);

  return (
    <div className={'footer-about'}>
      <p>
        <Text color="text.white" variant="meta">
            Upstox Securities Pvt. Ltd.: SEBI Registration No. INZ000315837 | NSE TM 
            Code: 13942 | BSE TM Code: 6155 | CDSL Reg No.: IN-DP-761-2024 | CIN: 
            U65100DL2021PTC376860 | Compliance Officer: Mr. Kapil Jaikalyani. 
            Tel No.: (022) 24229920. Email ID: <a href="mailto:compliance@upstox.com">
            compliance@upstox.com
            </a> | Registered Address: 
            809, New Delhi House, Barakhamba Road, Connaught Place, New Delhi - 110001 | 
            RKSV Commodities India Pvt. Ltd.: SEBI Registration No.: INZ000015837 | MCX TM 
            Code: 46510 | CIN: U74900DL2009PTC189166 | Compliance Officer: Mr. Amit Lalan. 
            Tel No.: (022) 24229920. Email ID: <a href="mailto:compliance@rksv.in">
            compliance@rksv.in
            </a> | Registered Address: 807, 
            New Delhi House, Barakhamba Road, Connaught Place, New Delhi - 110001. 
            Correspondence Address: 30th Floor, Sunshine Tower, Senapati Bapat Marg, Dadar 
            (West), Mumbai - 400013. | For any complaints, email at {' '}
            <a href="mailto:complaints@upstox.com">
              complaints@upstox.com
            </a> and {' '}
            <a href="mailto:complaints.mcx@upstox.com">
              complaints.mcx@upstox.com
            </a>.
        </Text>
      </p>

      <p>
        <Text color="text.white" variant="meta">
          Procedure to file a complaint on <a href={SEBI_SCORE_URL}> SEBI SCORES</a>: Register on the SCORES portal. 
          Mandatory details for filing complaints on SCORES include: Name, PAN, Address, 
          Mobile Number, and E-mail ID. Benefits include effective communication and speedy 
          redressal of grievances. Please ensure you carefully read the {' '}
          <a href="https://upstox.com/forms/">
          Risk Disclosure Document as prescribed by SEBI
          </a>, along with our {' '}
          <a href={PRIVACY_POLICY_URL}>Terms of Use and Privacy Policy</a>.
        </Text>
      </p>

      <p>
        <Text color="text.white" variant="meta">
        Upstox Securities Private Limited is a wholly owned subsidiary of RKSV
        Securities India Private Limited and RKSV Commodities India Private
        Limited is an associate of RKSV Securities India Private Limited.
        </Text>
      </p>

      <p>
        <Text color="text.white" variant="meta">
          Disclaimer: Investment in securities market are subject to market
          risks, read all the related documents carefully before investing.{' '}
          <br />
          *Brokerage will not exceed the SEBI prescribed limit
        </Text>
      </p>

      <p>
        <Text color="text.white" variant="meta">
          Attention Investors: As per NSE circular dated July 6, 2022, BSE
          circular dated July 6, 2022, MCX circular dated July 11, 2022
          investors are cautioned to abstain them from dealing in any schemes of
          unauthorised collective investments/portfolio management, indicative/
          guaranteed/fixed returns / payments etc. Investors are further
          cautioned to avoid practices like: <br />
          a) Sharing i) trading credentials – login id & passwords including
          OTP’s., ii) trading strategies, iii) position details. <br />
          b) Trading in leveraged products /derivatives like Options without
          proper understanding, which could lead to losses. <br />
          c) Writing/ selling options or trading in option strategies based on
          tips, without basic knowledge & understanding of the product and its
          risks <br />
          d) Dealing in unsolicited tips through like Whatsapp, Telegram,
          Instagram, YouTube, Facebook, SMS, calls, etc. <br />
          e) Trading / Trading in “Options” based on recommendations from
          unauthorised / unregistered investment advisors and influencers.
        </Text>
      </p>

      <p>
        <Text color="text.white" variant="meta">
          Kindly, read the Advisory Guidelines For Investors as prescribed by
          the Exchange with reference to their circular dated 27th August, 2021
          regarding investor awareness and safeguarding client's assets :
          <a href="https://bit.ly/3jwnuwA">https://bit.ly/3jwnuwA</a>
        </Text>
      </p>

      <p>
        <Text color="text.white" variant="meta">
          Kindly, read the advisory as prescribed by the Exchange with reference
          to their circular dated January 14, 2022 regarding Updation of
          mandatory KYC fields by March 31, 2022: <br />
          <a href="https://uptx.to/KYC-Updation">
            https://uptx.to/KYC-Updation
          </a>
        </Text>
      </p>

      <div className={'footer-about-readMore'}>
       
        {!readMore && (
          <>
           <p className={`${!readMore ? 'text-ellipse' : ''}`}>
           <Text color="text.white" variant="meta">
              Attention Investors: Prevent unauthorised transactions in your 
              Demat account by updating your mobile number with your depository 
              participant. Receive alerts on your registered mobile number for 
              debit and other important transactions in your Demat account directly 
              from CDSL on the same day. Prevent unauthorised transactions in your 
              Trading account by updating your mobile numbers/email addresses with 
              your stock brokers. Receive information on your transactions directly 
              from the Exchange on your mobile/email at the end of the day. Issued in 
              the interest of investors. KYC is a one-time exercise while dealing in 
              securities markets - once KYC is done through a SEBI-registered intermediary 
              (broker, DP, Mutual Fund, etc.), you need not undergo the same process again 
              when you approach another intermediary. As a business, we don’t give stock 
              tips and have not authorised anyone to trade on behalf of others. If you 
              find anyone claiming to be part of Upstox or RKSV and offering such services, 
              please send us an email at <a href="mailto:complaints@upstox.com">
              complaints@upstox.com
              </a> and <a href="mailto:complaints.mcx@upstox.com">
              complaints.mcx@upstox.com
              </a>.
           </Text>
         </p>
          <Text variant="meta">
            <a onClick={() => setReadMore(!readMore)} className={'read-more'}>
              Read More
            </a>
          </Text>
          </>
        )}
      </div>

      {readMore && (
        <>
          <p>
            <Text color="text.white" variant="meta">
              Attention Investors: Prevent unauthorised transactions in your 
              Demat account by updating your mobile number with your depository 
              participant. Receive alerts on your registered mobile number for 
              debit and other important transactions in your Demat account directly 
              from CDSL on the same day. Prevent unauthorised transactions in your 
              Trading account by updating your mobile numbers/email addresses with 
              your stock brokers. Receive information on your transactions directly 
              from the Exchange on your mobile/email at the end of the day. Issued in 
              the interest of investors. KYC is a one-time exercise while dealing in 
              securities markets - once KYC is done through a SEBI-registered intermediary 
              (broker, DP, Mutual Fund, etc.), you need not undergo the same process again 
              when you approach another intermediary. As a business, we don’t give stock 
              tips and have not authorised anyone to trade on behalf of others. If you 
              find anyone claiming to be part of Upstox or RKSV and offering such services, 
              please send us an email at <a href="mailto:complaints@upstox.com">
              complaints@upstox.com
              </a> and <a href="mailto:complaints.mcx@upstox.com">
              complaints.mcx@upstox.com
              </a>.
            </Text>
          </p>
          <p>
            <Text color="text.white" variant="meta">
              No need to issue cheques by investors while subscribing to IPO.
              Just write the bank account number and sign in the application
              form to authorise your bank to make payment in case of allotment.
              No worries for refund as the money remains in investor's account.
              <br /> Stockbrokers can accept securities as margin from their
              clients only by way of a pledge in the depository system w.e.f.
              1st September 2020. <br />
              Update your email ID and mobile number with your
              stockbroker/depository participant and receive an OTP directly
              from the depository on your registered email ID and/or mobile
              number to create a pledge.
              <br /> Check your securities/mutual funds/bonds in the
              Consolidated Account Statement (CAS) issued by NSDL/CDSL every
              month.
            </Text>
          </p>
          <b>
            <Text color="text.white" variant="meta">
              About Upstox
            </Text>
          </b>
          :<br />
          <br />
          <p>
            <Text color="text.white" variant="meta">
              Upstox, an online stock trading platform, is a trustworthy stock
              broker and one of the best trading apps in India, by people's
              choice. With highly intuitive features like investing chart view,
              investing live chart, and investing real time, it is undoubtedly
              one of the best trading platforms in India. With its
              cost-effective brokerage plans, this online share trading platform
              is open to all! With timely updates and features like investing
              chart view, you can spend more time planning your trading strategy
              and less on timing it. This stock broker platform will thereby
              reduce your time to place an order.
            </Text>
          </p>
          <p>
            <Text color="text.white" variant="meta">
              Online share trading is easier on Upstox. With Upstox Pro
              platforms you can do share market trading via web as well as
              mobile for unmatched convenience in online share trading. This
              share trading app lets you track all your securities, bonds, and
              mutual funds in one place. You can initiate buying or selling of
              securities and/or commodities and derivative holdings easily and
              quickly with the best trading app in India - Upstox Pro.
            </Text>
          </p>
          <p>
            <Text color="text.white" variant="meta">
              For those who are new to online stock trading, this Indian trading
              app provides you with resources and knowledge right from the
              basics to advanced levels. An easy to understand video series -
              'Learn with Upstox' will guide you on the basics of share market
              trading and also sharpen your investment strategy with varied
              investing chart views. It is the best stock market app and trusted
              by lakhs of Indian investors for a simple reason - It is a one
              stop destination from learning to trading.
            </Text>
          </p>
          <br />
          <b>
            <Text color="text.white" variant="meta">
              So, what are you waiting for?{' '}
            </Text>
          </b>
          <p>
            <Text color="text.white" variant="meta">
              <a href={DOWNLOAD_UPSTOX_URL}>Download Upstox Pro </a>
              and enjoy its services to trade online. Share trading is seamless
              when you can access the details of all the companies you are
              interested in on your android or apple phone. Depending on your
              financial goals and trading techniques you can engage in delivery
              trading and intraday trading. Once you open an Upstox account you
              can access both Upstox Pro web and Upstox Pro mobile trading
              platforms with the same ID and password and begin your online
              stock trading activities. Cross-platform access gives you the
              chance to trade from wherever you are!
            </Text>
          </p>
          <p>
            <Text color="text.white" variant="meta">
              If you still aren't on the best share market app then now is the
              time. Download Upstox Pro, your personal stock broker and
              experience seamless stock trading (web or mobile) from anywhere in
              India. Upstox, the best-in-class online trading app is committed
              to making investing simple, affordable and accessible, for
              everyone. Refer the 'Basic Brokerage Plan' below for important
              information about the lowest brokerage plans that you can get when
              you choose Upstox as your stock broker. Feel free to pick a plan
              that matches your stock trading needs. Simply the best stock
              broker friend you'll make. Happy investing!
            </Text>
          </p>
          <p>
            <Text color="text.white" variant="meta">
              We offer two types of brokerage plans that can help you take
              advantage of our discount stock trading services.
            </Text>
          </p>
          <br />
          <h2>
            <Text color="text.white" variant="heading3">
              Basic Brokerage Plan :{' '}
            </Text>
          </h2>
          <p>
            <Text color="text.white" variant="meta">
              Rs. 20 / order for Intraday trading, F&O, Currencies &
              Commodities.
              <em>Free Delivery trading</em> for life. No hidden charges.
            </Text>
          </p>
          <h2>
            <Text color="text.white" variant="heading3">
              Priority Brokerage Pack :
            </Text>
          </h2>
          <p>
            <Text color="text.white" variant="meta">
              Rs. 30 / order for Intraday trading, F&O, Currencies &
              Commodities. Free Delivery trading for life.{' '}
              <b>No hidden charges</b>.
              <br />
              Get <b>up to 25x margins</b> & <b>free equity delivery</b> trades
              for intraday/CO/OCO orders with a subscription to the Upstox{' '}
              <b>Priority pack.</b>
              <br />
              Go paperless and hassle-free.Your online stock broker Upstox is
              free to try and quick to use.
            </Text>
          </p>
          <br />
          <h4>
            <Text color="text.white" variant="heading3">
              Choose from a wide range of products offered at Upstox:
            </Text>
          </h4>
          <ul>
            <li>
              <Text color="text.white" variant="meta">
                Pro web
              </Text>
            </li>
            <li>
              <Text color="text.white" variant="meta">
                Pro mobile
              </Text>
            </li>
            <li>
              <Text color="text.white" variant="meta">
                Bridge for Amibroker
              </Text>
            </li>
            <li>
              <Text color="text.white" variant="meta">
                NEST Desktop app
              </Text>
            </li>
            <li>
              <Text color="text.white" variant="meta">
                Mutual Funds
              </Text>
            </li>
            <li>
              <Text color="text.white" variant="meta">
                Upstox Developer console
              </Text>
            </li>
            <li>
              <Text color="text.white" variant="meta">
                Fox Trader
              </Text>
            </li>
            <li>
              <Text color="text.white" variant="meta">
                Dartstock trading platform
              </Text>
            </li>
            <li>
              <Text color="text.white" variant="meta">
                Developer API
              </Text>
            </li>
          </ul>
          <h4>
            <Text color="text.white" variant="heading3">
              Features of Upstox Pro trading platform:
            </Text>
          </h4>
          <ul>
            <li>
              <Text color="text.white" variant="meta">
                Track your trade picks on multiple exchanges, the BSE, NSE, NFO,
                NCD, BCD and MCX—all from just one{' '}
                <a href={UPSTOX_PRODUCTS_URL}>online trading platform</a>.
              </Text>
            </li>
            <li>
              <Text color="text.white" variant="meta">
                Track commodities, market trends and monitor your picks using
                multiple charting indicators.
              </Text>
            </li>
            <li>
              <Text color="text.white" variant="meta">
                Indicators help you identify whether to buy, sell or just stay
                put at a particular point in time in the financial markets.They
                are simple mathematical ways to give you a better idea about
                market behavior.
              </Text>
            </li>
            <li>
              <Text color="text.white" variant="meta">
                Use a 100 indicators both on{' '}
                <a href={PRO_WEB_URL}>Upstox Pro web</a> and{' '}
                <a href={PRO_MOBILE_URL}>Upstox Pro mobile</a>.
              </Text>
            </li>
            <li>
              <Text color="text.white" variant="meta">
                You might be an intraday trader or might favour a long-term
                stock trading approach-- you have features here at Upstox Pro to
                enhance your online trading.
              </Text>
            </li>
            <li>
              <Text color="text.white" variant="meta">
                Easy-to-follow charts and graphs to help you make fast
                decisions.
              </Text>
            </li>
            <li>
              <Text color="text.white" variant="meta">
                Create price alerts to track market changes in real-time.{' '}
              </Text>
            </li>
            <li>
              <Text color="text.white" variant="meta">
                A feature to helps you place cover orders through the Upstox Pro
                app. Initially, traders could place cover orders ONLY via the
                Nest Trader Desktop app. Now it is available on your handheld
                device as well.
              </Text>
            </li>
          </ul>
        </>
      )}
      {readMore && (
        <Text variant="meta">
          <a onClick={() => setReadMore(!readMore)} className={'read-more'}>
            Read Less
          </a>
        </Text>
      )}
    </div>
  );
};

About.propTypes = {};

export default About;
