import { isEmpty } from 'lodash';
import moment from 'dayjs';
import axios from 'axios';
const STOCKS_URL = process.env.STOCKS_URL;

function removeSpecialChars(data) {
  data = data.replace(/[^A-Za-z/]/g, '-');
  data = data.replace(/-+/g, '-');
  data = data.replace(/\/\/+/g, '/');
  return data;
}

const saveBodInStorage = (searchableData) => {
  try {
    const date = moment().format('YYYY-MM-DD');
    const todayAt7 = moment(date + ' 07:00:00', 'YYYY-MM-DD HH:mm:ss');
    const isBefore7AM = moment().isBefore(todayAt7);
    const expiry = isBefore7AM
      ? todayAt7.format('YYYY-MM-DD HH:mm:ss')
      : todayAt7.add(1, 'day').format('YYYY-MM-DD HH:mm:ss');

    const storageData = {
      expires: expiry,
      lastFetchTime: moment().format('YYYY-MM-DD HH:mm:ss'),
    };
    if (searchableData) {
      storageData['data'] = searchableData;
    }

    localStorage.setItem('investStocks', JSON.stringify(storageData));
    return true;
  } catch (e) {
    return false;
  }
};

const fetchBod = async () => {
  try {
    const response = await axios.get(STOCKS_URL);
    if (!response?.data?.bse) {
      throw new Error('Failed to fetch bod');
    }
    const seoString = '-share-price';
    let searchableData = response?.data?.bse.eq
      .filter((stock) => {
        const stocksData = stock.split(',');
        const groupList = ['F'];
        const condition =
          !groupList.includes(stocksData[3]) &&
          !(stocksData[2].toLowerCase().search('mutual') !== -1);
        return condition;
      })
      .map((stock) => {
        const stocksData = stock.split(',');
        const name = removeSpecialChars(stocksData[2].toLowerCase());
        stocksData.push(
          'BSE',
          'eq',
          `/stocks/${name}${seoString}/${stocksData[7]}/`,
        );
        return stocksData;
      });

    searchableData = searchableData.concat(
      response?.data?.nse?.eq
        .filter((stock) => {
          const stocksData = stock.split(',');
          return stocksData[3] === 'EQ';
        })
        .map((stock) => {
          const stocksData = stock.split(',');
          const name = removeSpecialChars(stocksData[2].toLowerCase());
          stocksData.push(
            'NSE',
            'eq',
            `/stocks/${name}${seoString}/${stocksData[7]}/`,
          );
          return stocksData;
        }),
    );

    searchableData = searchableData.concat(
      response?.data?.bse?.i?.map((indices) => {
        const stocksData = new Array(11).fill('');
        stocksData[2] = indices;
        stocksData[8] = 'BSE';
        const name = removeSpecialChars(indices.toLowerCase());
        stocksData[10] = `/indices/${name}${seoString}/`;
        return stocksData;
      }),
    );

    searchableData = searchableData.concat(
      response?.data?.nse?.i?.map((indices) => {
        const stocksData = new Array(11).fill('');
        stocksData[2] = indices;
        stocksData[8] = 'NSE';
        const name = removeSpecialChars(indices.toLowerCase());
        stocksData[10] = `/indices/${name}${seoString}/`;
        return stocksData;
      }),
    );
    const data = searchableData;
    const isinObj = {};
    data.forEach((stock) => {
      const isin = stock[7];
      isinObj[isin] = isinObj[isin] ? ++isinObj[isin] : 1;
    });
    const filteredList = data.filter((stock) => {
      const isin = stock[7];
      const exchange = stock[8];

      if (isinObj[isin] === 2 && exchange === 'BSE') {
        return false;
      }
      return true;
    });

    saveBodInStorage(filteredList);
    return { success: false, data: filteredList };
  } catch (e) {
    saveBodInStorage(null);
    return { success: false, data: [] };
  }
};

const getBodFromStorage = () => {
  try {
    const data = JSON.parse(localStorage.getItem('investStocks'));
    if (
      !isEmpty(data) &&
      !isEmpty(data.data) &&
      data.expires &&
      moment(data.expires, 'YYYY-MM-DD HH:mm:ss').isAfter(moment())
    ) {
      return { success: true, stocks: data.data };
    }
    const lastFetchTime = data ? data.lastFetchTime : null;
    return { success: false, lastFetchTime: lastFetchTime };
  } catch (e) {
    return { success: false, lastFetchTime: null };
  }
};

export const getStocksData = async () => {
  try {
    const storageData = getBodFromStorage();
    if (storageData.success) {
      return storageData.stocks;
    }

    if (
      !storageData.lastFetchTime ||
      !moment(storageData.lastFetchTime).isValid() ||
      moment().isAfter(moment(storageData.lastFetchTime).add(30, 'seconds'))
    ) {
      const apiData = await fetchBod();
      return apiData.data;
    }
    return [];
  } catch (e) {
    return [];
  }
};
