import React, { FunctionComponent } from 'react';
import { Text } from '@upstox/upstox-ui';
import Menu from '../Menu';
import {
  indianIndicesData1,
  indianIndicesData2,
  indianIndicesData3,
  indianIndicesData4,
} from './constants';

const IndianIndices: FunctionComponent = () => {
  return (
    <section className={'footer-tab-data'}>
      <div className="row">
        <div className="col-3">
          <p className={'footer-menu-title'}>
            <Text>{'Indian Stocks'}</Text>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-3 mb35">
          <Menu
            menuTitle=""
            menuItems={indianIndicesData1}
            className="menuContainer"
          />
        </div>
        <div className="col-3 mb35">
          <Menu
            menuTitle=""
            menuItems={indianIndicesData2}
            className="menuContainer"
          />
        </div>
        <div className="col-3 mb35">
          <Menu
            menuTitle=""
            menuItems={indianIndicesData3}
            className="menuContainer"
          />
        </div>
        <div className="col-3 mb35">
          <Menu
            menuTitle=""
            menuItems={indianIndicesData4}
            className="menuContainer"
          />
        </div>
      </div>
    </section>
  );
};

IndianIndices.propTypes = {};

export default IndianIndices;
