import {
  NIFTY_50_SHARE_PRICE,
  NIFTY_NEXT_50_SHARE_PRICE,
  NIFTY_BANK_SHARE_PRICE,
  NIFTY_100_SHARE_PRICE,
  NIFTY_200_SHARE_PRICE,
  NIFTY_500_SHARE_PRICE,
  NIFTY_MIDCAP_50_SHARE_PRICE,
  NIFTY_SMLCAP_50_SHARE_PRICE,
  INDIA_VIX_SHARE_PRICE,
  SENSEX_SHARE_PRICE,
  BSE100_SHARE_PRICE,
  LRGCAP_SHARE_PRICE,
  MIDCAP_SHARE_PRICE,
  SMLCAP_SHARE_PRICE,
  BSE500_SHARE_PRICE,
  ALLCAP_SHARE_PRICE,
  BSE200_SHARE_PRICE,
  BSEIPO_SHARE_PRICE,
  SMEIPO_SHARE_PRICE,
} from '../../Models/Actions/Footer/url-helper';

export const indianIndicesData1 = [
  {
    link: NIFTY_50_SHARE_PRICE,
    text: 'Nifty 50 share price',
  },
  {
    link: NIFTY_200_SHARE_PRICE,
    text: 'Nifty 200 share price',
  },
  {
    link: INDIA_VIX_SHARE_PRICE,
    text: 'India VIX share price',
  },
  {
    link: MIDCAP_SHARE_PRICE,
    text: 'MIDCAP share price',
  },
  {
    link: BSE200_SHARE_PRICE,
    text: 'BSE200 share price',
  },
];

export const indianIndicesData2 = [
  {
    link: NIFTY_NEXT_50_SHARE_PRICE,
    text: 'Nifty Next 50 share price',
  },
  {
    link: NIFTY_500_SHARE_PRICE,
    text: 'Nifty 500 share price',
  },
  {
    link: SENSEX_SHARE_PRICE,
    text: 'SENSEX share price',
  },
  {
    link: SMLCAP_SHARE_PRICE,
    text: 'SMLCAP share price',
  },
  {
    link: BSEIPO_SHARE_PRICE,
    text: 'BSEIPO share price',
  },
];

export const indianIndicesData3 = [
  {
    link: NIFTY_BANK_SHARE_PRICE,
    text: 'Nifty Bank share price',
  },
  {
    link: NIFTY_MIDCAP_50_SHARE_PRICE,
    text: 'Nifty Midcap 50 share price',
  },
  {
    link: BSE100_SHARE_PRICE,
    text: 'BSE100 share price',
  },
  {
    link: BSE500_SHARE_PRICE,
    text: 'BSE500 share price',
  },
  {
    link: SMEIPO_SHARE_PRICE,
    text: 'SMEIPO share price',
  },
];

export const indianIndicesData4 = [
  {
    link: NIFTY_100_SHARE_PRICE,
    text: 'Nifty 100 share price',
  },
  {
    link: NIFTY_SMLCAP_50_SHARE_PRICE,
    text: 'NIFTY SMLCAP 50 share price',
  },
  {
    link: LRGCAP_SHARE_PRICE,
    text: 'LRGCAP share price',
  },
  {
    link: ALLCAP_SHARE_PRICE,
    text: 'ALLCAP share price',
  },
];
