// modules
import React, { useMemo } from 'react';
import cx from 'classnames';
// typings
import { TabProps } from './typings';
// styles
import styles from './styles.module.scss';
import { Text } from '@upstox/upstox-ui';

const Tab = ({
  id,
  title,
  isActive,
  onTabSelect,
  className,
  activeTabClassName,
  activeIndicatorPosition,
  disabled,
}: TabProps) => {
  const activeClassName = useMemo(
    () => activeTabClassName ?? '',
    [activeTabClassName],
  );

  function handleTabSelect() {
    !disabled && onTabSelect(id);
  }

  return (
    <div
      className={cx(
        styles.tab,
        { [styles.disabled]: disabled },
        { [styles.active]: isActive },
        { [activeClassName]: isActive },
        styles[activeIndicatorPosition!],
        className,
      )}
      onClick={handleTabSelect}
    >
      {typeof title === 'string' ? (
        <span data-id={title}>
          <Text color="text.white">{title}</Text>
        </span>
      ) : (
        title
      )}
    </div>
  );
};

Tab.defaultProps = {
  id: '',
  title: '',
  isActive: false,
  activeIndicatorPosition: 'top',
};

export default Tab;
