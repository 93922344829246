import { spacing, color } from '@upstox/upstox-ui';

export const cardWrapper: CSSFunction = ({ theme }) => ({
  padding: spacing(theme, ['xlarge', 'medium', 40]),
});
export const actionButton: CSSFunction = ({ theme }) => ({
  '& a': {
    width: '100%',
    textAlign: 'center',
  },
});

export const cardStyles: CSSFunction = ({ theme }) => ({
  backgroundColor: color(theme, 'ui1'),
  borderRadius: '8px',
  width: '100%',
});
