import React, { FunctionComponent } from 'react';
import { Text } from '@upstox/upstox-ui';

type Props = {
  title?: string;
  baseUrl?: string;
};

const AlphabeticLinks: FunctionComponent<Props> = ({
  title,
  baseUrl,
}: {
  title: string;
  baseUrl: string;
}) => {
  return (
    <section>
      <div className="footer-alphabats-link">
        <span className="footer-alpha-list-title">
          <Text color="text.white">{title} </Text>
        </span>

        <ul className="footer-alpha-list footer-list-unstyled">
          {[
            ...new Array(26).fill(1).map((_, i) => String.fromCharCode(65 + i)),
            'Others',
          ].map((alpha) => (
            <li key={alpha} className="footer-alpha-list-items">
              <a href={baseUrl + alpha.toLowerCase() + '/'}>
                <Text color="text.white">{alpha}</Text>{' '}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default AlphabeticLinks;
