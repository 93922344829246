import { memo, useEffect, useState } from 'react';
import { Drawer as UiDrawer } from '@upstox/upstox-ui';

const Drawer = (props: DrawerProps) => {
  let [root, setRoot] = useState(undefined);

  useEffect(() => {
    setRoot(window.document.getElementById('drawerRoot'));
  }, [root]);

  return <UiDrawer container={root} {...props} />;
};

export default memo(Drawer);
