import {
  FACEBOOK_URL,
  TWITTER_URL,
  YOUTUBE_URL,
  INSTAGRAM_URL,
  TELEGRAM_URL,
  LINKEDIN_URL,
} from '../../Models/Actions/Footer/url-helper';

import Icon from '@mdi/react';

import {
  mdiFacebook,
  mdiTwitter,
  mdiYoutube,
  mdiInstagram,
  mdiSend,
  mdiLinkedin,
} from '@mdi/js';

export const socialData = [
  {
    text: 'Facebook',
    link: FACEBOOK_URL,
    icon: <Icon size={1} path={mdiFacebook} color="#8292a3" />,
  },
  {
    text: 'Twitter',
    link: TWITTER_URL,
    icon: <Icon size={1} path={mdiTwitter} color="#8292a3" />,
  },
  {
    text: 'Telegram',
    link: TELEGRAM_URL,
    icon: <Icon size={1} path={mdiSend} rotate={-35} color="#8292a3" />,
  },
  {
    text: 'Linkedin',
    link: LINKEDIN_URL,
    icon: <Icon size={1} path={mdiLinkedin} color="#8292a3" />,
  },
  {
    text: 'Youtube',
    link: YOUTUBE_URL,
    icon: <Icon size={1} path={mdiYoutube} color="#8292a3" />,
  },
  {
    text: 'Instagram',
    link: INSTAGRAM_URL,
    icon: <Icon size={1} path={mdiInstagram} color="#8292a3" />,
  },
];
