//@ts-nocheck
import React, { FunctionComponent } from 'react';
import Menu from '../Menu';
import { Text } from '@upstox/upstox-ui';
import {
  savingSchemes1,
  savingSchemes2,
  savingSchemes3,
  savingSchemes4,
} from './constants';

const SavingSchemes: FunctionComponent = () => {
  return (
    <section className={'footer-tab-data'}>
      <div className="row">
        <div className="col">
          <p className={'footer-menu-title'}>
            <Text>{'Saving Schemes'}</Text>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-3 mb35">
          <Menu
            menuTitle=""
            menuItems={savingSchemes1}
            className="menuContainer"
          />
        </div>
        <div className="col-3 mb35">
          <Menu
            menuTitle=""
            menuItems={savingSchemes2}
            className="menuContainer"
          />
        </div>

        <div className="col-3 mb35">
          <Menu
            menuTitle=""
            menuItems={savingSchemes3}
            className="menuContainer"
          />
        </div>

        <div className="col-3 mb35">
          <Menu
            menuTitle=""
            menuItems={savingSchemes4}
            className="menuContainer"
          />
        </div>
      </div>
    </section>
  );
};

SavingSchemes.propTypes = {};

export default SavingSchemes;
