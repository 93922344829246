import React, { FunctionComponent, useEffect, useState } from 'react';
import { View, Text, Card, Button } from '@upstox/upstox-ui';
import * as css from './InvestIpoCard.style';
import NextLink from 'next/link';
import { getOnboardingUrl, getSignInUrl } from '../../helpers';

interface Props {
  className?: string;
}

const OngoingIpo: FunctionComponent<Props> = ({ className = '' }) => {
  const [onboardingUrl, setOnboardingUrl] = useState<string>('');
  useEffect(() => {
    setOnboardingUrl(getOnboardingUrl());
  }, []);

  return (
    <View className={`${className} full-width`} style={{ marginTop: '84px' }}>
      <Card css={css.cardStyles}>
        <View flexDirection="column" css={css.cardWrapper}>
          <View margin={[0, 0, 24]}>
            <Text variant="heading2">Interested in investing in an IPO?</Text>
          </View>
          <View margin={[0, 0, 16]}>
            <Text color="text.2" style={{ fontSize: 16 }}>
              Open free Demat + trading account and join our 1 crore+ successful
              users.
            </Text>
          </View>
          <View margin={[0, 0, 24]} css={css.actionButton}>
            <a href={onboardingUrl} style={{ color: 'inherit' }}>
              <Button
                size="large"
                width="full"
                style={{ fontSize: 16 }}
                as="div"
              >
                Open Demat Account
              </Button>
            </a>
          </View>
          <View margin={[0, 0, 16]}>
            <Text color="text.2" style={{ fontSize: 16 }}>
              Already have an account with Upstox?
            </Text>
          </View>
          <View css={css.actionButton}>
            <Button
              size="large"
              width="full"
              style={{ fontSize: 16 }}
              as="div"
              onClick={() => window.open(getSignInUrl(), '_self')}
            >
              Login
            </Button>
          </View>
        </View>
      </Card>
    </View>
  );
};

export default OngoingIpo;
