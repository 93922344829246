import {
  UPSTOX_BASE_URL,
  PRIVACY_POLICY_URL,
  CAREERS_URL,
} from '../../Models/Actions/Footer/url-helper';

export const copyRightData = [
  {
    text: 'Sitemap',
    link: `${UPSTOX_BASE_URL}sitemap/`,
  },
  {
    text: 'Terms of Use and Privacy Policy',
    link: PRIVACY_POLICY_URL,
  },
  {
    text: 'Trust & Security',
    link: `${UPSTOX_BASE_URL}trust-security/`,
  },
  {
    text: 'Careers',
    link: CAREERS_URL,
  },
  {
    text: 'Contact Us',
    link: `${UPSTOX_BASE_URL}contact-us/`,
  },
  {
    text: 'Investor Charter',
    link: `${UPSTOX_BASE_URL}investor-attention/`,
  },
];
