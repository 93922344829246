import React, { FunctionComponent } from 'react';
import Menu from '../Menu';
import { companyData } from './constants';

const Company: FunctionComponent = () => {
  return (
    <section>
      <Menu menuTitle="Company" menuItems={companyData} />
    </section>
  );
};

Company.propTypes = {};

export default Company;
