import React, { FunctionComponent } from 'react';
import Head from 'next/head';
import { View } from '@upstox/upstox-ui';
import * as css from './Layout.style';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import useCurrentWidth from '../../common/hooks/useCurrentWidth';
import Footer from '../Footer';
import Signup from '../Signup';

interface Props {
  pageTitle: string;
  pageHeader?: React.ReactNode;
  showSidebar?: boolean;
  allowFooterBtmSpacing?: boolean;
}

const Layout: FunctionComponent<Props> = ({
  children,
  pageTitle,
  pageHeader,
  showSidebar = true,
  allowFooterBtmSpacing = false,
}) => {
  const screenWidth = useCurrentWidth();

  const footerBtmSpacing = {
    footerBtmSpacing: allowFooterBtmSpacing,
  };

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
      </Head>
      <View css={css.container} flexDirection="column">
        <Header />
        {pageHeader && pageHeader}
        <View
          css={css.wrapper}
          // gap={!screenWidth && screenWidth <= 1200 ? 'medium' : 'xxxlarge'}
          // flexWrap={screenWidth > 0 && screenWidth <= 1024 ? 'wrap' : 'nowrap'}
        >
          <View as="main" css={css.main} flexDirection="column">
            {children}
          </View>
          {showSidebar && <Sidebar />}
        </View>

        <View css={css.footer} cssProps={footerBtmSpacing}>
          <Footer />
        </View>
        {screenWidth > 0 && screenWidth < 1024 && <Signup />}
      </View>
    </>
  );
};

export default Layout;
