// @ts-nocheck - may need to be at the start of file
import React, { useState, useEffect } from 'react';
import { Text, Card, View, Button } from '@upstox/upstox-ui';
import * as css from './Signup.style';
import { getOnboardingUrl } from '../../helpers';

const Signup = () => {
  const [onboardingUrl, setOnboardingUrl] = useState<string>('');

  useEffect(() => {
    setOnboardingUrl(getOnboardingUrl());
  }, []);

  return (
    <View css={css.marginButton}>
      <a href={onboardingUrl}>Open Demat Account</a>
    </View>
  );
};

export default Signup;
