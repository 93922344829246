import { Text } from '@upstox/upstox-ui';
import React, { FunctionComponent } from 'react';
import { UPSTOX_BASE_URL } from '../../Models/Actions/Footer/url-helper';
import { copyRightData } from './constants';

const Copyright: FunctionComponent = () => {
  const getYear = () => {
    return new Date().getFullYear();
  };

  return (
    <div className={'footer-copyright'}>
      <ul>
        {copyRightData.map((link, index) => {
          return (
            <li key={index}>
              <a target="_blank" href={link.link}>
                <Text color="text.white" variant="meta" weight="semiBold">
                  <span>{link.text}</span>
                </Text>
              </a>
            </li>
          );
        })}
      </ul>
      <p className={'footer-copyright-author'}>
        <Text color="text.white" variant="meta" weight="semiBold">
          Made with ❤️ in India | Copyright &copy; {getYear()},
          <a href={UPSTOX_BASE_URL}>
            <em> Upstox</em>
          </a>
        </Text>
      </p>
    </div>
  );
};

Copyright.propTypes = {};

export default Copyright;
