import {
  DEMAT_ACCOUNT_URL,
  TRADING_ACCOUNT_URL,
  COMMODITY_TRADING_URL,
  EQUITY_TRADING_URL,
  DOWNLOAD_FORMS_URL,
  GLOSSARY_URL,
  HELP_CENTER_URL,
  MARGIN_SHEETS_URL,
} from '../../Models/Actions/Footer/url-helper';

export const servicesData = [
  {
    link: DEMAT_ACCOUNT_URL,
    text: 'Demat Account',
  },
  {
    link: TRADING_ACCOUNT_URL,
    text: 'Trading Account',
  },
  {
    link: COMMODITY_TRADING_URL,
    text: 'Commodity Trading',
  },
  {
    link: EQUITY_TRADING_URL,
    text: 'Equity Trading',
  },
  {
    link: DOWNLOAD_FORMS_URL,
    text: 'Download Forms',
  },
  {
    link: GLOSSARY_URL,
    text: 'Glossary',
  },
  {
    link: HELP_CENTER_URL,
    text: 'Help Center',
  },
];
