import {
  BROKERAGE_CALCULATOR,
  NPV_CALCULATOR,
  OPTION_VALUE_CALCULATOR,
  NSC_CALCULATOR,
  GRATUITY_CALCULATOR,
  HRA_CALCULATOR,
  MARGIN_CALCULATOR,
  FUTURE_VALUE_CALCULATOR,
  NPS_CALCULATOR,
  SUKANYA_SAMRIDDHI_YOJANA_CALCULATOR,
  SIMPLE_INTEREST_CALCULATOR,
  MUTUAL_FUND_RETURNS_CALCULATOR,
  SWP_CALCULATOR,
  PPF_CALCULATOR,
  COMPOUND_INTEREST_CALCULATOR,
  FD_CALCULATOR,
  SIP_CALCULATOR,
  ELSS_CALCULATOR,
  CAGR_CALCULATOR,
  APY_CALCULATOR,
  GST_CALCULATOR,
  HDFC_FD_CALCULATOR,
  YES_BANK_FD_CALCULATOR,
  RBL_FD_CALCULATOR,
  KOTAK_BANK_FD_CALCULATOR,
  KARNATAKA_BANK_FD_CALCULATOR,
  ICICI_FD_CALCULATOR,
  INDIAN_BANK_FD_CALCULATOR,
  SBI_FD_CALCULATOR,
  UCO_BANK_FD_CALCULATOR,
  IDFC_BANK_FD_CALCULATOR,
  CANARA_BANK_FD_CALCULATOR,
  BANDHAN_BANK_FD_CALCULATOR,
  BAJAJ_FINSERV_FD_CALCULATOR,
  CENTERAL_BANK_FD_CALCULATOR,
  IDBI_BANK_FD_CALCULATOR,
  AXIS_FD_CALCULATOR,
  UNION_BANK_FD_CALCULATOR,
  INDUSIND_BANK_FD_CALCULATOR,
  FEDERAL_BANK_FD_CALCULATOR,
  BOM_FD_CALCULATOR,
  INCOME_TAX_CALCULATOR,
} from '../../Models/Actions/Footer/url-helper';

export const calculatorData1 = [
  { link: BROKERAGE_CALCULATOR, text: 'Brokerage Calculator' },
  { link: NPV_CALCULATOR, text: 'NPV Calculator' },
  { link: OPTION_VALUE_CALCULATOR, text: 'Option Value Calculator' },
  { link: NSC_CALCULATOR, text: 'NSC Calculator' },
  { link: GRATUITY_CALCULATOR, text: 'Gratuity calculator' },
  { link: HRA_CALCULATOR, text: 'HRA Calculator' },
  { link: HDFC_FD_CALCULATOR, text: 'HDFC FD Calculator' },
  { link: YES_BANK_FD_CALCULATOR, text: 'YES Bank FD Calculator' },
  { link: RBL_FD_CALCULATOR, text: 'RBL FD Calculator' },
  { link: KOTAK_BANK_FD_CALCULATOR, text: 'KOTAK Bank FD Calculator' },
  { link: KARNATAKA_BANK_FD_CALCULATOR, text: 'Karnataka Bank FD Calculator' },
];

export const calculatorData2 = [
  { link: MARGIN_CALCULATOR, text: 'Margin Calculator' },
  { link: FUTURE_VALUE_CALCULATOR, text: 'Future Value Calculator' },
  { link: NPS_CALCULATOR, text: 'NPS Calculator' },
  {
    link: SUKANYA_SAMRIDDHI_YOJANA_CALCULATOR,
    text: 'Sukanya Samriddhi Yojana Calculator',
  },
  { link: SIMPLE_INTEREST_CALCULATOR, text: 'Simple Interest Calculator' },
  { link: ICICI_FD_CALCULATOR, text: 'ICICI FD Calculator' },
  { link: INDIAN_BANK_FD_CALCULATOR, text: 'Indian Bank Fd calculator' },
  { link: SBI_FD_CALCULATOR, text: 'SBI FD Calculator' },
  { link: UCO_BANK_FD_CALCULATOR, text: 'UCO Bank FD Calculator' },
  { link: IDFC_BANK_FD_CALCULATOR, text: 'IDFC Bank FD Calculator' },
];

export const calculatorData3 = [
  {
    link: MUTUAL_FUND_RETURNS_CALCULATOR,
    text: 'Mutual Fund Returns Calculator',
  },
  { link: SWP_CALCULATOR, text: 'SWP Calculator' },
  { link: PPF_CALCULATOR, text: 'PPF Calculator' },
  { link: COMPOUND_INTEREST_CALCULATOR, text: 'Compound Interest calculator' },
  { link: FD_CALCULATOR, text: 'FD Calculator' },
  { link: CANARA_BANK_FD_CALCULATOR, text: 'CANARA Bank FD Calculator' },
  { link: BANDHAN_BANK_FD_CALCULATOR, text: 'Bandhan Bank FD Calculator' },
  { link: BAJAJ_FINSERV_FD_CALCULATOR, text: 'Bajaj Finserv FD Calculator' },
  { link: CENTERAL_BANK_FD_CALCULATOR, text: 'Central Bank FD Calculator' },
  { link: IDBI_BANK_FD_CALCULATOR, text: 'IDBI Bank FD Calculator' },
];

export const calculatorData4 = [
  { link: SIP_CALCULATOR, text: 'SIP Calculator' },
  { link: ELSS_CALCULATOR, text: 'ELSS Calculator' },
  { link: CAGR_CALCULATOR, text: 'CAGR Calculator' },
  { link: APY_CALCULATOR, text: 'APY Calculator' },
  { link: GST_CALCULATOR, text: 'GST Calculator' },
  { link: AXIS_FD_CALCULATOR, text: 'AXIS FD Calculator' },
  { link: UNION_BANK_FD_CALCULATOR, text: 'Union Bank FD Calculator' },
  { link: INDUSIND_BANK_FD_CALCULATOR, text: 'IndusInd Bank FD Calculator' },
  { link: FEDERAL_BANK_FD_CALCULATOR, text: 'Federal Bank FD Calculator' },
  { link: BOM_FD_CALCULATOR, text: 'BOM FD Calculator' },
  { link: INCOME_TAX_CALCULATOR, text: 'Income Tax Calculator ' },
];
