export const tabData = [
  {
    title: 'Popular Stocks',
  },
  {
    title: 'Future & Options',
  },
  {
    title: 'Indian Indices',
  },
  {
    title: 'Calculators',
  },
  {
    title: 'Learning Center',
  },
  {
    title: 'More',
  },
  {
    title: 'Banking',
  },
  {
    title: 'Saving Account',
  },
  {
    title: 'Saving Schemes',
  },
  {
    title: 'IPO Learn',
  },
  {
    title: 'F&O - Learning',
  },
];
