import React, { FunctionComponent } from 'react';
import { Text } from '@upstox/upstox-ui';
import Menu from '../Menu';

import {
  calculatorData1,
  calculatorData2,
  calculatorData3,
  calculatorData4,
} from './constants';

const Calculators: FunctionComponent = () => {
  return (
    <section className={'footer-tab-data'}>
      <div className="row">
        <div className="col-3">
          <p className={'footer-menu-title'}>
            <Text>{'Calculators'}</Text>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-3 mb35">
          <Menu
            menuTitle=""
            menuItems={calculatorData1}
            className="menuContainer"
          />
        </div>

        <div className="col-3 mb35">
          <Menu
            menuTitle=""
            menuItems={calculatorData2}
            className="menuContainer"
          />
        </div>

        <div className="col-3 mb35">
          <Menu
            menuTitle=""
            menuItems={calculatorData3}
            className="menuContainer"
          />
        </div>

        <div className="col-3 mb35">
          <Menu
            menuTitle=""
            menuItems={calculatorData4}
            className="menuContainer"
          />
        </div>
      </div>
    </section>
  );
};

Calculators.propTypes = {};

export default Calculators;
