//@ts-nocheck
import React, { FunctionComponent } from 'react';
import Menu from '../Menu';
import { Text } from '@upstox/upstox-ui';
import { banking1, banking2, banking3, banking4 } from './constants';

const Banking: FunctionComponent = () => {
  return (
    <section className={'footer-tab-data'}>
      <div className="row">
        <div className="col">
          <p className={'footer-menu-title'}>
            <Text>Banking</Text>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-3 mb35">
          <Menu menuTitle="" menuItems={banking1} className="menuContainer" />
        </div>
        <div className="col-3 mb35">
          <Menu menuTitle="" menuItems={banking2} className="menuContainer" />
        </div>
        <div className="col-3 mb35">
          <Menu menuTitle="" menuItems={banking3} className="menuContainer" />
        </div>
        <div className="col-3 mb35">
          <Menu menuTitle="" menuItems={banking4} className="menuContainer" />
        </div>
      </div>
    </section>
  );
};

Banking.propTypes = {};

export default Banking;
