import React, { FunctionComponent } from 'react';
import Menu from '../Menu';
import { Text } from '@upstox/upstox-ui';
import {
  popularStocks1,
  popularStocks2,
  popularStocks3,
  popularStocks4,
} from './constants';

const PopularStocks: FunctionComponent = () => {
  return (
    <section className={'footer-tab-data'}>
      <div className="row">
        <div className="col">
          <p className={'footer-menu-title'}>
            <Text>{'Popular Stocks'}</Text>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-3 mb35">
          <Menu
            menuTitle=""
            menuItems={popularStocks1}
            className="menuContainer"
          />
        </div>
        <div className="col-3 mb35">
          <Menu
            menuTitle=""
            menuItems={popularStocks2}
            className="menuContainer"
          />
        </div>

        <div className="col-3 mb35">
          <Menu
            menuTitle=""
            menuItems={popularStocks3}
            className="menuContainer"
          />
        </div>

        <div className="col-3 mb35">
          <Menu
            menuTitle=""
            menuItems={popularStocks4}
            className="menuContainer"
          />
        </div>
      </div>
    </section>
  );
};

PopularStocks.propTypes = {};

export default PopularStocks;
