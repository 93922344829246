import { color, spacing } from '@upstox/upstox-ui';

export const headerWrapper: CSSFunction = ({ theme }) => ({
  marginBottom: spacing(theme, [64]),
});
export const navMenu: CSSFunction = ({ theme }) => ({
  '> a': {
    textDecoration: 'none !important',
  },
});
export const signIn: CSSFunction = ({ theme }) => ({
  '& > a': {
    boxShadow: `0px 0px 0px 1px ${color(
      theme,
      'interactive.primary.default',
    )} inset`,
    color: color(theme, 'interactive.primary.default'),
    ':hover': {
      background: `${color(theme, 'interactive.row.hover')} !important`,
    },
  },
});

export const logoWhite: CSSFunction = ({ theme }) => ({
  '& svg': {
    '& path': {
      fill: color(theme, 'background.white'),
    },
  },
});
export const mobileSearch: CSSFunction = ({ theme }) => ({
  position: 'absolute',
  background: color(theme, 'background.default'),
  padding: spacing(theme, ['small', 'medium']),
  top: 0,
  left: 0,
  width: '100%',
  zIndex: 3,
  '& > div': {
    width: '100%',
    marginRight: spacing(theme, ['medium']),
  },
});
