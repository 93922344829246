import {
  HDFC_BANK_OPTION_CHAIN,
  NSE_OPTION_CHAIN,
  TATA_STEEL_OPTION_CHAIN,
  WIPRO_OPTION_CHAIN,
  COAL_INDIA_OPTION_CHAIN,
  PNB_OPTION_CHAIN,
  SAIL_OPTION_CHAIN,
  NTPC_OPTION_CHAIN,
  ITC_OPTION_CHAIN,
  HDFC_BANK_FUTURE_SHARE_PRICE,
  BANKNIFTY_OPTION_CHAIN,
  TCS_OPTION_CHAIN,
  SBI_OPTION_CHAIN,
  AXIS_BANK_OPTION_CHAIN,
  DLF_OPTION_CHAIN,
  RIL_OPTION_CHAIN,
  ASIAN_PAINTS_OPTION_CHAIN,
  TATA_MOTERS_FUTURE_SHARE_PRICE,
  DLF_FUTURE_SHARE_PRICE,
  NIFTY_CALL_OPTION,
  RELIANCE_OPTION_CHAIN,
  INFOSYS_OPTION_CHAIN,
  MARUTI_OPTION_CHAIN,
  HINDALCO_OPTION_CHAIN,
  BAJAJ_FINANCE_OPTION_CHAIN,
  BHARTI_AIRTEL_OPTION_CHAIN,
  BANKNIFTY_CALL_OPTION,
  TATA_STEEL_FUTURE_SHARE_PRICE,
  TATA_MOTERS_OPTION_CHAIN,
  TATA_POWER_OPTION_CHAIN,
  ICICI_BANK_OPTION_CHAIN,
  HINDUSTAN_UNILIVER_OPTION_CHAIN,
  IRCTC_OPTION_CHAIN,
  CIPLA_OPTION_CHAIN,
  NIFTY_FUTURE_SHARE_PRICE,
  INFOSYS_FUTURE_SHARE_PRICE,
  WIPRO_FUTURE_SHARE_PRICE,
} from '../../Models/Actions/Footer/url-helper';

export const fnoData1 = [
  {
    link: NSE_OPTION_CHAIN,
    text: 'NSE Option Chain',
  },
  {
    link: HDFC_BANK_OPTION_CHAIN,
    text: 'Hdfc Bank Option Chain',
  },
  {
    link: TATA_STEEL_OPTION_CHAIN,
    text: 'Tata Steel Option Chain',
  },
  {
    link: WIPRO_OPTION_CHAIN,
    text: 'Wipro Option Chain',
  },
  {
    link: COAL_INDIA_OPTION_CHAIN,
    text: 'Coal India Option Chain',
  },
  {
    link: PNB_OPTION_CHAIN,
    text: 'Pnb Option Chain',
  },
  {
    link: SAIL_OPTION_CHAIN,
    text: 'Sail Option Chain',
  },
  {
    link: NTPC_OPTION_CHAIN,
    text: 'Ntpc Option Chain',
  },
  {
    link: ITC_OPTION_CHAIN,
    text: 'Itc Future Share Price',
  },
  {
    link: HDFC_BANK_FUTURE_SHARE_PRICE,
    text: 'Hdfc Bank Future Share Price',
  },
];

export const fnoData2 = [
  {
    link: BANKNIFTY_OPTION_CHAIN,
    text: 'Banknifty Option Chain',
  },
  {
    link: TCS_OPTION_CHAIN,
    text: 'Tcs Option Chain',
  },
  {
    link: SBI_OPTION_CHAIN,
    text: 'Sbi Option Chain',
  },
  {
    link: AXIS_BANK_OPTION_CHAIN,
    text: 'Axis Bank Option Chain',
  },
  {
    link: DLF_OPTION_CHAIN,
    text: 'DIf Option Chain',
  },
  {
    link: RIL_OPTION_CHAIN,
    text: 'Ril Option Chain',
  },
  {
    link: ASIAN_PAINTS_OPTION_CHAIN,
    text: 'Asian Paints Option Chain',
  },
  {
    link: NIFTY_CALL_OPTION,
    text: 'Nifty Call Option',
  },
  {
    link: TATA_MOTERS_FUTURE_SHARE_PRICE,
    text: 'Tata Motors Future Share Price',
  },
  {
    link: DLF_FUTURE_SHARE_PRICE,
    text: 'Dlf Future Share Price',
  },
];

export const fnoData3 = [
  {
    link: RELIANCE_OPTION_CHAIN,
    text: 'Reliance Option Chain',
  },
  {
    link: INFOSYS_OPTION_CHAIN,
    text: 'Infosys Option Chain',
  },
  {
    link: MARUTI_OPTION_CHAIN,
    text: 'Maruti Option Chain',
  },
  {
    link: HINDALCO_OPTION_CHAIN,
    text: 'Hindalco Option Chain',
  },
  {
    link: BAJAJ_FINANCE_OPTION_CHAIN,
    text: 'Bajaj Finance Option Chain',
  },
  {
    link: BHARTI_AIRTEL_OPTION_CHAIN,
    text: 'Bharti Airtel Option Chain',
  },
  {
    link: BANKNIFTY_CALL_OPTION,
    text: 'Banknifty Call Option',
  },
  {
    link: TATA_STEEL_FUTURE_SHARE_PRICE,
    text: 'Tata Steel Future Share Price',
  },
  {
    link: DLF_FUTURE_SHARE_PRICE,
    text: 'Icici Bank Future Share Price',
  },
];

export const fnoData4 = [
  {
    link: ITC_OPTION_CHAIN,
    text: 'Itc Option Chain',
  },
  {
    link: TATA_MOTERS_OPTION_CHAIN,
    text: 'Tata Motors Option Chain',
  },
  {
    link: TATA_POWER_OPTION_CHAIN,
    text: 'Tata Power Option Chain',
  },
  {
    link: ICICI_BANK_OPTION_CHAIN,
    text: 'Icici Bank Option Chain',
  },
  {
    link: HINDUSTAN_UNILIVER_OPTION_CHAIN,
    text: 'Hindustan Unilever Option Chain',
  },
  {
    link: IRCTC_OPTION_CHAIN,
    text: 'Irctc Option Chain',
  },
  {
    link: CIPLA_OPTION_CHAIN,
    text: 'Cipla Option Chain',
  },
  {
    link: NIFTY_FUTURE_SHARE_PRICE,
    text: 'Nifty Future Share Price',
  },
  {
    link: INFOSYS_FUTURE_SHARE_PRICE,
    text: 'Infosys Future Share Price',
  },
  {
    link: WIPRO_FUTURE_SHARE_PRICE,
    text: 'Wipro Future Share Price',
  },
];
