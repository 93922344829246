import React, { FunctionComponent, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  View,
  Text,
  AddIcon,
  RemoveIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@upstox/upstox-ui';

type Props = {
  title: string | React.ReactNode;
  defaultActive?: boolean;
  className?: string;
  children?: React.ReactNode;
  iconPlacement?: 'left' | 'right';
  contentPlacement?: 'top' | 'bottom';
  iconType?: 'arrow' | 'plus';
  activeTitle?: string;
  showIcon?: boolean;
  headerClassName?: string;
  bodyClassName?: string;
};

const Collapse: FunctionComponent<Props> = ({
  title,
  defaultActive = false,
  iconPlacement,
  contentPlacement,
  className,
  children,
  iconType,
  activeTitle,
  showIcon,
  headerClassName = '',
  bodyClassName = '',
}) => {
  const [toggle, setToggle] = useState('');
  const [icon, setIcon] = useState(
    iconType === 'arrow' ? (
      <ChevronDownIcon size="small" />
    ) : (
      <AddIcon size="small" />
    ),
  );

  useEffect(() => {
    if (defaultActive) {
      setToggle('is-open');
      setIcon(
        iconType === 'arrow' ? (
          <ChevronUpIcon size="small" />
        ) : (
          <RemoveIcon size="small" />
        ),
      );
    }
  }, [defaultActive]);

  const toggleActive = () => {
    setToggle(toggle === '' ? 'is-open' : '');
    setIcon(
      toggle === 'is-open' ? (
        iconType === 'arrow' ? (
          <ChevronDownIcon size="small" />
        ) : (
          <AddIcon size="small" />
        )
      ) : iconType === 'arrow' ? (
        <ChevronUpIcon size="small" />
      ) : (
        <RemoveIcon size="small" />
      ),
    );
  };

  return (
    <div className={`collapse ${className || ''}`}>
      <div
        className="collapse-item"
        data-icon-placement={iconPlacement}
        data-content-placement={contentPlacement}
      >
        <div
          className={`collapse-header ${headerClassName}`}
          onClick={toggleActive}
          is-aria-expanded={toggle}
        >
          <View>
            <Text as="h3" variant="bodyBold">
              {activeTitle && toggle === 'is-open' ? activeTitle : title}
            </Text>
          </View>
          {showIcon && (
            <div data-icon="indicator-icon" data-open={toggle}>
              {icon}
            </div>
          )}
        </div>
        <div className={`collapse-body ${bodyClassName}`} is-expanded={toggle}>
          <View as="div" id="test">
            <Text variant="body" color="text.2">
              {children}
            </Text>
          </View>
        </div>
      </div>
    </div>
  );
};

Collapse.defaultProps = {
  iconPlacement: 'right',
  contentPlacement: 'bottom',
  iconType: 'arrow',
  showIcon: true,
  defaultActive: false,
};

Collapse.propTypes = {
  title: PropTypes.node.isRequired,
  className: PropTypes.string,
  defaultActive: PropTypes.bool,
  children: PropTypes.node,
  iconPlacement: PropTypes.oneOf(['left', 'right']),
  contentPlacement: PropTypes.oneOf(['top', 'bottom']),
  iconType: PropTypes.oneOf(['arrow', 'plus']),
  activeTitle: PropTypes.string,
  showIcon: PropTypes.bool,
};

export default Collapse;
