import Collapse from '../../Collapse';
import { View, Text } from '@upstox/upstox-ui';
import Head from 'next/head';
import { Faqs as FaqsType } from 'store/types';

const faqSchema = (faqs: FaqsType[]) =>
  JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqs?.map((faq) => ({
      '@type': 'Question',
      name: faq?.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq?.answer,
      },
    })),
  });

const Faqs = ({ faqs, title }) => {
  const filteredFaqs = faqs.filter((faq: FaqsType) => faq.question !== '');
  return (
    <>
      {filteredFaqs && filteredFaqs?.length > 0 && (
        <>
          <Head>
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: faqSchema(filteredFaqs),
              }}
            ></script>
          </Head>
          <View flexDirection="column" className="faqWrapper">
            <View className="mb15">
              <Text as="h2" variant="bodyBold" size="m">
                {title}
              </Text>
            </View>

            <View flexDirection="column">
              {filteredFaqs.map((faq: FaqsType, index) => (
                <Collapse
                  title={faq.question}
                  key={faq.serialNo}
                  className="collapseContainer"
                  headerClassName="header"
                  bodyClassName="body"
                  iconType="plus"
                >
                  <div dangerouslySetInnerHTML={{ __html: faq.answer }}></div>
                </Collapse>
              ))}
            </View>
          </View>
        </>
      )}
    </>
  );
};

export default Faqs;
