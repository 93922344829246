import {
  ABOUT_US_URL,
  MARKET_TALK_URL,
  BROKERAGE_CHARGES_URL,
  REFER_AND_EARN_URL,
  ANNOUNCEMENTS_URL,
  PARTNER_URL,
  PRESS_RELEASE_URL,
  BUG_BOUNTY_URL,
  CONTACT_US_URL,
  MEDIA_KIT_URL,
} from '../../Models/Actions/Footer/url-helper';

export const companyData = [
  {
    text: 'About Us',
    link: ABOUT_US_URL,
  },
  {
    text: 'Market Talk',
    link: MARKET_TALK_URL,
  },
  {
    text: 'Brokerage Charges',
    link: BROKERAGE_CHARGES_URL,
  },
  {
    text: 'Refer and Earn',
    link: REFER_AND_EARN_URL,
  },
  {
    text: 'Announcements',
    link: ANNOUNCEMENTS_URL,
  },
  {
    text: 'Partner with us',
    link: PARTNER_URL,
  },
  {
    text: 'Press Releases',
    link: PRESS_RELEASE_URL,
  },
  {
    text: 'Bug Bounty Program',
    link: BUG_BOUNTY_URL,
  },
  {
    text: 'Contact Us',
    link: CONTACT_US_URL,
  },
  {
    text: 'Media Kit',
    link: MEDIA_KIT_URL,
  },
];
