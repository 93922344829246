//@ts-nocheck
import React, { FunctionComponent } from 'react';
import Menu from '../Menu';
import { Text } from '@upstox/upstox-ui';
import { ipoLearn1, ipoLearn2, ipoLearn3, ipoLearn4 } from './constants';

const IPOLearn: FunctionComponent = () => {
  return (
    <section className={'footer-tab-data'}>
      <div className="row">
        <div className="col">
          <p className={'footer-menu-title'}>
            <Text>{'IPO Learn'}</Text>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-3 mb35">
          <Menu menuTitle="" menuItems={ipoLearn1} className="menuContainer" />
        </div>
        <div className="col-3 mb35">
          <Menu menuTitle="" menuItems={ipoLearn2} className="menuContainer" />
        </div>

        <div className="col-3 mb35">
          <Menu menuTitle="" menuItems={ipoLearn3} className="menuContainer" />
        </div>

        <div className="col-3 mb35">
          <Menu menuTitle="" menuItems={ipoLearn4} className="menuContainer" />
        </div>
      </div>
    </section>
  );
};

IPOLearn.propTypes = {};

export default IPOLearn;
