import {
  DERIVATIVE_URL,
  FUTURES_AND_OPTIONS_LEARN_URL,
  FUTURES_TRADING_LEARN_URL,
  OVER_THE_COUNTER_URL,
  UNDERLAYING_ASSET_URL,
  CALL_WRITING_URL,
  OPTIONS_TRADER_URL,
  BERMUDA_OPTIONS,
  OPTIONS_TRADING_URL,
  CALL_OPTION_URL,
  COMMODITY_FUTURES_URL,
  DERIVATIVE_TRADING_URL,
  SPOT_PRICE_URL,
  EXCHAGNE_TRADED_DERIVATIVES_URL,
  COMMODITY_OPTIONS_URL,
  DIAGONAL_SPREAD_URL,
  INDEX_FUTUERS_URL,
  COLLATERALIZED_DEBT_OBLIGATIONS_URL,
  STRICKE_PRICE_URL,
  SHORT_COVERING_URL,
  OPTION_PREMIUM_URL,
  PROTECTIVE_PUT_URL,
  HEDGING_WITH_FUTURES_URL,
  OPTION_TRADING_STRATEGIES_URL,
  FORWARD_CONTRACT_URL,
  LTP_URL,
  EQUITY_DERIVATIVES_URL,
  HEDGING_STRATEGY_URL,
  CASH_SECURED_PUTS_URL,
  PUT_WRITING_URL,
} from '../../Models/Actions/Footer/url-helper';

export const fnoLearning1 = [
  { link: DERIVATIVE_URL, text: 'Derivative' },
  { link: FUTURES_AND_OPTIONS_LEARN_URL, text: 'futures and options' },
  { link: FUTURES_TRADING_LEARN_URL, text: 'Futures Trading' },
  { link: OVER_THE_COUNTER_URL, text: 'Over The Counter' },
  { link: UNDERLAYING_ASSET_URL, text: 'Underlying Asset' },
  { link: CALL_WRITING_URL, text: 'Call Writing' },
  { link: OPTIONS_TRADER_URL, text: 'Options Trader' },
  { link: BERMUDA_OPTIONS, text: 'Bermuda Options' },
];

export const fnoLearning2 = [
  { link: OPTIONS_TRADING_URL, text: 'Options Trading' },
  { link: CALL_OPTION_URL, text: 'Call Option' },
  { link: COMMODITY_FUTURES_URL, text: 'Commodity Futures' },
  { link: DERIVATIVE_TRADING_URL, text: 'Derivative Trading' },
  { link: SPOT_PRICE_URL, text: 'Spot Price' },
  {
    link: EXCHAGNE_TRADED_DERIVATIVES_URL,
    text: 'Exchange Traded Derivatives',
  },
  { link: COMMODITY_OPTIONS_URL, text: 'Commodity Options' },
  { link: DIAGONAL_SPREAD_URL, text: 'Diagonal Spread' },
];

export const fnoLearning3 = [
  { link: INDEX_FUTUERS_URL, text: 'Index Futures' },
  {
    link: COLLATERALIZED_DEBT_OBLIGATIONS_URL,
    text: 'Collateralized Debt Obligations',
  },
  { link: STRICKE_PRICE_URL, text: 'Strike Price' },
  { link: SHORT_COVERING_URL, text: 'Short Covering' },
  { link: OPTION_PREMIUM_URL, text: 'Option Premium' },
  { link: PROTECTIVE_PUT_URL, text: 'Protective Put' },
  { link: HEDGING_WITH_FUTURES_URL, text: 'Hedging with Futures' },
];

export const fnoLearning4 = [
  { link: OPTION_TRADING_STRATEGIES_URL, text: 'Options Trading Strategies' },
  { link: FORWARD_CONTRACT_URL, text: 'Forward Contract' },
  { link: LTP_URL, text: 'LTP' },
  { link: EQUITY_DERIVATIVES_URL, text: 'Equity Derivatives' },
  { link: HEDGING_STRATEGY_URL, text: 'Hedging Strategy' },
  { link: CASH_SECURED_PUTS_URL, text: 'Cash Secured Puts' },
  { link: PUT_WRITING_URL, text: 'Put Writing' },
];
