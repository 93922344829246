import React, { FunctionComponent } from 'react';
import { View, Text } from '@upstox/upstox-ui';
import * as css from './Sidebar.style';
import InvestIpoCard from '../InvestIpoCard';

const Sidebar: FunctionComponent = () => {
  return (
    <View css={css.wrapper} alignItems="flex-start">
      <InvestIpoCard className="mt84" />
    </View>
  );
};

export default Sidebar;
