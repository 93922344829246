import React, { FunctionComponent } from 'react';
import { MenuProps } from './typings';
import PropTypes from 'prop-types';
import { Text } from '@upstox/upstox-ui';

const Menu: FunctionComponent<MenuProps> = ({
  menuTitle,
  menuItems,
  className,
  rel,
}) => {
  return (
    <div className={`footer-menu ${className}`}>
      <p className={'footer-menu-title'}>
        <Text color="text.white" variant="meta">
          {menuTitle}
        </Text>
      </p>
      <ul className={'footer-menu-list'}>
        {menuItems.map((item, index) => {
          return (
            <li className={'footer-menu-list-items'} key={index}>
              <a href={item.link} target="_blank" rel={rel}>
                {item.icon}
                <Text color="text.white" variant="meta">
                  <span>{item.text}</span>
                </Text>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

Menu.defaultProps = {
  className: '',
};

Menu.propTypes = {
  menuTitle: PropTypes.string,
  menuItems: PropTypes.array,
  className: PropTypes.string,
};

export default Menu;
