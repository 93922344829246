import { isEmpty } from 'lodash';
import React, { FunctionComponent, useEffect, useState } from 'react';
import * as css from './SearchBox.style';
import { View, Text, SearchBar } from '@upstox/upstox-ui';
import { getStocksData } from '../../common/services/stocksService';

const BASE_URL = process.env.BASE_URL;
const SearchBox: FunctionComponent = () => {
  const [stocksData, setStocksData] = useState([]);
  const [matches, setMatches] = useState([]);
  const [searchTerm, setSearchTerm] = useState();

  useEffect(() => {
    if (isEmpty(stocksData)) {
      getStocksData().then((data) => setStocksData(data));
    }
  }, []);

  const handleSearchChange = async (value) => {
    if (value && value.length >= 3) {
      const term = value.toLowerCase();
      const matches = stocksData.filter(
        (stock) =>
          stock[2].toLowerCase().includes(term) ||
          (!!stock[1] && stock[1].toLowerCase().includes(term)),
      );

      setMatches(matches);
    } else {
      setMatches([]);
    }
  };

  const handleOnClick = (url) => {
    setMatches([]);
    setSearchTerm('');
    window.open(BASE_URL + url, '_self');
  };

  const getMatchedRows = () => {
    return matches.map((stock) => {
      return (
        <View
          flexDirection="column"
          css={css.item}
          className="full-width"
          key={stock[0]}
          onClick={() => {
            handleOnClick(stock[10]);
          }}
        >
          <Text weight="bold">{stock[2]}</Text>
          <Text color="text.2">{stock[1]}</Text>
        </View>
      );
    });
  };

  return (
    <View css={css.searchWrapper}>
      <SearchBar
        onChange={(e) => {
          setSearchTerm(e?.target?.value);
          handleSearchChange(e?.target?.value);
        }}
        placeholder="Search any stock (E.g: AXIS BANK)"
        variant="searchForm"
        className="searchBar"
        value={searchTerm}
      />
      {matches && matches.length > 0 && (
        <View css={css.searchList} flexDirection="column">
          {getMatchedRows()}
        </View>
      )}
    </View>
  );
};

export default SearchBox;
