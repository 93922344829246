import React, { FunctionComponent, useState } from 'react';
import useCurrentWitdh from '../../common/hooks/useCurrentWidth';
import About from './About';
import Company from './Company';
import Copyright from './Copyright';
import LearningCenter from './LearningCenter';
import Products from './Products';
import Services from './Services';
import Social from './Social';
import Tab from '../../components/Tabs/components/Tab';
import AppLinks from './AppLinks';
import AlphabeticLinks from './AlphabeticLinks';
import { STOCKS_ALPHABATIC_BASE_URL } from '../Models/Actions/Footer/url-helper';
import PopularStocks from './PopularStocks';
import FuturesAndOptions from './FuturesAndOptions';
import IndianIndices from './IndianIndices';
import Calculators from './Calculators';
import More from './More';
import Banking from './Banking';
import SavingAccount from './SavingAccount';
import SavingSchemes from './SavingSchemes';
import IPOLearn from './IPOLearn';
import FnOLearning from './FnOLearning';
import { tabData } from './constants';

type Props = {
  wrapperClass?: string;
};

const Footer: FunctionComponent<Props> = ({ wrapperClass }) => {
  // -1 specifies no tab open
  const [tab1Id, setTab1Id] = useState(-1);
  const [tab2Id, setTab2Id] = useState(-1);

  const handleTabChange = (id: number, tab: number) => {
    // if tab itself open close it
    // else open it
    if (tab === 0) {
      setTab2Id(-1);
      if (tab1Id === id) {
        setTab1Id(-1);
      } else {
        setTab1Id(id);
      }
    } else if (tab === 1) {
      setTab1Id(-1);
      if (tab2Id === id) {
        setTab2Id(-1);
      } else {
        setTab2Id(id);
      }
    }
  };

  const screenWidth = useCurrentWitdh();

  return (
    <footer className={`footer ${wrapperClass}`}>
      <div className={'footer-links'}>
        <AppLinks />
        <Products />
        <Company />
        <Services />
        <Social />
      </div>

      <AlphabeticLinks title={'Stocks'} baseUrl={STOCKS_ALPHABATIC_BASE_URL} />
      <div className="footer-links-wrapper">
        <div className={'footer-02'}>
          {tabData.slice(0, 6).map((tab, index) => {
            return (
              <>
                <Tab
                  id={index}
                  title={tab.title}
                  key={index}
                  onTabSelect={(id) => handleTabChange(id, 0)}
                  isActive={index === tab1Id}
                  className={'footer-tabs'}
                  activeTabClassName={'footer-tabs-active'}
                />
                {screenWidth > 0 && screenWidth < 768 && tab1Id !== -1 && (
                  <>
                    {tab1Id === 0 && index === 0 && <PopularStocks />}
                    {tab1Id === 1 && index === 1 && <FuturesAndOptions />}
                    {tab1Id === 2 && index === 2 && <IndianIndices />}
                    {tab1Id === 3 && index === 3 && <Calculators />}
                    {tab1Id === 4 && index === 4 && <LearningCenter />}
                    {tab1Id === 5 && index === 5 && <More />}
                  </>
                )}
              </>
            );
          })}
        </div>
        {!screenWidth ||
          (screenWidth >= 768 && tab1Id !== -1 && (
            <div style={{ paddingLeft: '15px' }}>
              {tab1Id === 0 && <PopularStocks />}
              {tab1Id === 1 && <FuturesAndOptions />}
              {tab1Id === 2 && <IndianIndices />}
              {tab1Id === 3 && <Calculators />}
              {tab1Id === 4 && <LearningCenter />}
              {tab1Id === 5 && <More />}
            </div>
          ))}

        <div className={'footer-02'}>
          {tabData.slice(6).map((tab, index) => {
            return (
              <>
                <Tab
                  id={index}
                  title={tab.title}
                  key={index}
                  onTabSelect={(id) => handleTabChange(id, 1)}
                  isActive={index === tab2Id}
                  className={'footer-tabs'}
                  activeTabClassName={'footer-tabs-active'}
                />
                {tab2Id !== -1 && screenWidth > 0 && screenWidth < 768 && (
                  <>
                    {tab2Id === 0 && index === 0 && <Banking />}
                    {tab2Id === 1 && index === 1 && <SavingAccount />}
                    {tab2Id === 2 && index === 2 && <SavingSchemes />}
                    {tab2Id === 3 && index === 3 && <IPOLearn />}
                    {tab2Id === 4 && index === 4 && <FnOLearning />}
                  </>
                )}
              </>
            );
          })}
        </div>
        {!screenWidth ||
          (screenWidth >= 768 && tab2Id !== -1 && (
            <div style={{ paddingLeft: '15px' }}>
              {tab2Id === 0 && <Banking />}
              {tab2Id === 1 && <SavingAccount />}
              {tab2Id === 2 && <SavingSchemes />}
              {tab2Id === 3 && <IPOLearn />}
              {tab2Id === 4 && <FnOLearning />}
            </div>
          ))}
      </div>

      <div>
        <About />
        <Copyright />
      </div>
    </footer>
  );
};

export default Footer;
