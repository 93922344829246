import {
  MGNREGA_URL,
  DIGITAL_SEVA_PORTAL_URL,
  SUKANYA_SAMRIDDHI_YOJANA_URL,
  EPF_CLAIM_STATUS_URL,
  PMJJBY_URL,
  PRAN_URL,
  KISAN_VIKAS_PATRA_URL,
  PRADHAN_MANTRI_JAN_DHAN_YOJNA_URL,
  UAN_MEMBER_PORTAL_URL,
  PMJAY_URL,
  ATAL_PENSION_YOJANA_URL,
  FORM_15G,
  SENIOR_CITIZEN_SAVINGS_SCHEME_URL,
  KANYA_SUMANGALA_YOJANA,
  GPF_URL,
  POST_OFFICE_MONTHLY_INCOME_SCHEME_URL,
  PM_KISAN_SAMMAN_NIDHI_YOJANA_URL,
  PMAY_URL,
  PPF_INTEREST_RATE_URL,
  BETI_BACHAO_BETI_PADHAO_URL,
  PMSBY_URL,
  EPF_BALANCE_URL,
  NSC_INTEREST_RATE_URL,
  NPS_URL,
  PMGAY_URL,
  PMMVY_URL,
  GRATUITY_URL,
  POST_OFFICE_SAVING_SCHEMES_URL,
  EPF_WITHDRAWAL_ONLINE_URL,
  EPF_INTEREST_RATE_URL,
} from '../../Models/Actions/Footer/url-helper';

export const savingSchemes1 = [
  { link: MGNREGA_URL, text: 'MGNREGA' },
  { link: DIGITAL_SEVA_PORTAL_URL, text: 'Digital Seva Portal' },
  { link: SUKANYA_SAMRIDDHI_YOJANA_URL, text: 'Sukanya Samriddhi Yojana' },
  { link: EPF_CLAIM_STATUS_URL, text: 'EPF Claim Status' },
  { link: PMJJBY_URL, text: 'PMJJBY' },
  { link: PRAN_URL, text: 'PRAN' },
  { link: KISAN_VIKAS_PATRA_URL, text: 'Kisan Vikas Patra' },
  {
    link: PRADHAN_MANTRI_JAN_DHAN_YOJNA_URL,
    text: 'Pradhan Mantri Jan Dhan Yojana',
  },
];

export const savingSchemes2 = [
  { link: UAN_MEMBER_PORTAL_URL, text: 'UAN Member Portal' },
  { link: PMJAY_URL, text: 'PMJAY' },
  { link: ATAL_PENSION_YOJANA_URL, text: 'Atal Pension Yojana' },
  { link: FORM_15G, text: 'Form 15G' },
  {
    link: SENIOR_CITIZEN_SAVINGS_SCHEME_URL,
    text: 'Senior Citizen Savings Scheme',
  },
  { link: KANYA_SUMANGALA_YOJANA, text: 'Kanya Sumangala Yojana' },
  { link: GPF_URL, text: 'GPF' },
  {
    link: POST_OFFICE_MONTHLY_INCOME_SCHEME_URL,
    text: 'Post Office Monthly Income Scheme',
  },
];

export const savingSchemes3 = [
  {
    link: PM_KISAN_SAMMAN_NIDHI_YOJANA_URL,
    text: 'PM Kisan Samman Nidhi Yojana',
  },
  { link: PMAY_URL, text: 'PMAY' },
  { link: PPF_INTEREST_RATE_URL, text: 'PPF Interest Rate' },
  { link: BETI_BACHAO_BETI_PADHAO_URL, text: 'Beti Bachao Beti Padhao' },
  { link: PMSBY_URL, text: 'PMSBY' },
  { link: EPF_BALANCE_URL, text: 'EPF Balance' },
  { link: NSC_INTEREST_RATE_URL, text: 'NSC Interest Rate' },
];

export const savingSchemes4 = [
  { link: NPS_URL, text: 'NPS' },
  { link: PMGAY_URL, text: 'PMGAY' },
  { link: PMMVY_URL, text: 'PMMVY' },
  { link: GRATUITY_URL, text: 'Gratuity' },
  { link: POST_OFFICE_SAVING_SCHEMES_URL, text: 'Post Office Saving Schemes' },
  { link: EPF_WITHDRAWAL_ONLINE_URL, text: 'Epf Withdrawal Online' },
  { link: EPF_INTEREST_RATE_URL, text: 'EPF Interest Rate' },
];
