import { color, spacing } from '@upstox/upstox-ui';

export const searchWrapper: CSSFunction = ({ theme }) => ({
  position: 'relative',

  '& .searchBar': {
    height: 32,
  },
});
export const searchList: CSSFunction = ({ theme }) => ({
  position: 'absolute',
  top: 45,
  background: color(theme, 'background.default'),
  width: '100%',
  maxHeight: 300,
  overflowY: 'scroll',
  borderBottom: '1px solid #ccc',
  borderRadius: 5,
});
export const item: CSSFunction = ({ theme }) => ({
  padding: spacing(theme, ['xsmall', 'small']),
  border: '1px solid #ccc',
  cursor: 'pointer',
  borderBottom: 0,
  width: '100%',
  '&:first-child': {
    borderRadius: '5px 5px 0 0',
  },
  '&:last-child': {
    borderRadius: '0 0 5px 5px',
  },
  '&:hover': {
    background: color(theme, 'disabled.1'),
  },
});
