import {
  STOCK_MARKET_HOLIDAYS_URL,
  INVEST_URL,
  NSE_OIL_AND_GAS_SECTOR_STOCKS_URL,
  NSE_BANKING_STOCKS_URL,
  TOP_GAINERS_URL,
  TRADE_URL,
  NSE_IT_STOCKS_URL,
  MARGIN_TRADING_FACILITY_URL,
  TOP_LOSERS_URL,
  NSE_CEMENT_STOCKS_URL,
  NSE_METAL_STOCKS_URL,
  OPTION_STRATEGIES_URL,
  STOCK_URL,
  NSE_AUTOMIBILES_STOCKS_URL,
  NSE_PHARMA_STOCKS_URL,
  SHARE_MARKET_LISTED_COMPANY_IN_INDIA_URL,
} from '../../Models/Actions/Footer/url-helper';

export const moreDataList1 = [
  {
    link: STOCK_MARKET_HOLIDAYS_URL,
    text: 'Stock Market Holidays',
  },
  {
    link: INVEST_URL,
    text: 'Invest',
  },
  {
    link: NSE_OIL_AND_GAS_SECTOR_STOCKS_URL,
    text: 'NSE Oil and Gas Sector Stocks',
  },
  {
    link: NSE_BANKING_STOCKS_URL,
    text: 'NSE Banking Stocks',
  },
];

export const moreDataList2 = [
  {
    link: TOP_GAINERS_URL,
    text: 'Top Gainers',
  },
  {
    link: TRADE_URL,
    text: 'Trade',
  },
  {
    link: NSE_IT_STOCKS_URL,
    text: 'NSE IT Stocks',
  },
  {
    link: MARGIN_TRADING_FACILITY_URL,
    text: 'Margin Trading Facility',
  },
];

export const moreDataList3 = [
  {
    link: TOP_LOSERS_URL,
    text: 'Top Losers',
  },
  {
    link: NSE_CEMENT_STOCKS_URL,
    text: 'NSE Cement Stocks',
  },
  {
    link: NSE_METAL_STOCKS_URL,
    text: 'NSE Metal Stocks',
  },
  {
    link: OPTION_STRATEGIES_URL,
    text: 'Option Strategies',
  },
];

export const moreDataList4 = [
  {
    link: STOCK_URL,
    text: 'Stocks',
  },
  {
    link: NSE_AUTOMIBILES_STOCKS_URL,
    text: 'NSE Automobile Stocks',
  },
  {
    link: NSE_PHARMA_STOCKS_URL,
    text: 'NSE Pharma Stocks',
  },
  {
    link: SHARE_MARKET_LISTED_COMPANY_IN_INDIA_URL,
    text: 'Share Market Listed Company In India',
  },
];
