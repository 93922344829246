import {
  TATA_MOTORS_SHARE_PRICE,
  ITC_SHARE_PRICE,
  YES_BANK_SHARE_PRICE,
  HDFC_BANK_SHARE_PRICE,
  BHEL_SHARE_PRICE,
  ONGC_SHARE_PRICE,
  ADANI_PORT_SHARE_PRICE,
  VODAFONE_IDEA_SHARE_PRICE,
  VEDANTA_SHARE_PRICE,
  CIPLA_SHARE_PRICE,
  TATA_CHEMICALS_SHARE_PRICE,
  MARUTI_SHARE_PRICE,
  ASIAN_PAINTS_SHARE_PRICE,
  GAIL_SHARE_PRICE,
  DLF_SHARE_PRICE,
  IRCTC_SHARE_PRICE,
  TATA_POWER_SHARE_PRICE,
  INFOSYS_SHARE_PRICE,
  TCS_SHARE_PRICE,
  WIPRO_SHARE_PRICE,
  SUZLON_SHARE_PRICE,
  BAJA_FINANCE_SHARE_PRICE,
  ASHOK_LELAND_SHARE_PRICE,
  IEX_SHARE_PRICE,
  AXIS_BANK_SHARE_PRICE,
  DMART_SHARE_PRICE,
  NBCC_SHARE_PRICE,
  CDSL_SHARE_PRICE,
  RELIANCE_POWER_SHARE_PRICE,
  BANK_OF_BARODA_SHARE_PRICE,
  SBI_SHARE_PRICE,
  LIC_SHARE_PRICE,
  PNB_SHARE_PRICE,
  ADANI_GREEN_SHARE_PRICE,
  IRFC_SHARE_PRICE,
  HFCL_SHARE_PRICE,
  HAPPIEST_MINDS_SHARE_PRICE,
  IOC_SHARE_PRICE,
  CANARA_BANK_SHARE_PRICE,
  PAYTM_SHARE_PRICE,
  NALCO_SHARE_PRICE,
  JSW_STEEL_SHARE_PRICE,
  HINDALCO_SHARE_PRICE,
  IFC_FIRST_BANK_SHARE_PRICE,
  MRF_SHARE_PRICE,
  RELIANCE_SHARE_PRICE,
  ADANI_POWER_SHARE_PRICE,
  TATA_STEEL_SHARE_PRICE,
  SAIL_SHARE_PRICE,
  ZOMATO_SHARE_PRICE,
  BPCL_SHARE_PRICE,
  SBI_CARD_SHARE_PRICE,
  ICICI_BANK_SHARE_PRICE,
  COAL_INDIA_SHARE_PRICE,
  HUL_SHARE_PRICE,
  INDUSIND_BANK_SHARE_PRICE,
  SUBEX_SHARE_PRICE,
  L_AND_T_SHARE_PRICE,
  BANDHAN_BANK_SHARE_PRICE,
} from '../../Models/Actions/Footer/url-helper';

export const popularStocks1 = [
  { link: TATA_MOTORS_SHARE_PRICE, text: 'Tata Motors Share Price' },
  { link: ITC_SHARE_PRICE, text: 'ITC Share Price' },
  { link: YES_BANK_SHARE_PRICE, text: 'Yes Bank Share Price' },
  { link: HDFC_BANK_SHARE_PRICE, text: 'HDFC Bank Share Price' },
  { link: BHEL_SHARE_PRICE, text: 'BHEL Share Price' },
  { link: ONGC_SHARE_PRICE, text: 'ONGC Share Price' },
  { link: ADANI_PORT_SHARE_PRICE, text: 'Adani Port Share Price' },
  { link: VODAFONE_IDEA_SHARE_PRICE, text: 'Vodafone Idea Share Price' },
  { link: VEDANTA_SHARE_PRICE, text: 'Vedanta Share Price' },
  { link: CIPLA_SHARE_PRICE, text: 'Cipla Share Price' },
  { link: TATA_CHEMICALS_SHARE_PRICE, text: 'Tata Chemicals Share Price' },
  { link: MARUTI_SHARE_PRICE, text: 'Maruti Share Price' },
  { link: ASIAN_PAINTS_SHARE_PRICE, text: 'Asian Paints Share Price' },
  { link: GAIL_SHARE_PRICE, text: 'Gail Share Price' },
  { link: DLF_SHARE_PRICE, text: 'DLF Share Price' },
];

export const popularStocks2 = [
  { link: IRCTC_SHARE_PRICE, text: 'IRCTC Share Price' },
  { link: TATA_POWER_SHARE_PRICE, text: 'Tata Power Share Price' },
  { link: INFOSYS_SHARE_PRICE, text: 'Infosys Share Price' },
  { link: TCS_SHARE_PRICE, text: 'TCS Share Price' },
  { link: WIPRO_SHARE_PRICE, text: 'Wipro Share Price' },
  { link: SUZLON_SHARE_PRICE, text: 'Suzlon Share Price' },
  { link: BAJA_FINANCE_SHARE_PRICE, text: 'Baja Finance Share Price' },
  { link: ASHOK_LELAND_SHARE_PRICE, text: 'Ashok Leyland Share Price' },
  { link: IEX_SHARE_PRICE, text: 'IEX Share Price' },
  { link: AXIS_BANK_SHARE_PRICE, text: 'Axis Bank Share Price' },
  { link: DMART_SHARE_PRICE, text: 'Dmart Share Price' },
  { link: NBCC_SHARE_PRICE, text: 'NBCC Share Price' },
  { link: CDSL_SHARE_PRICE, text: 'CDSL Share Price' },
  { link: RELIANCE_POWER_SHARE_PRICE, text: 'Reliance Power Share Price' },
  { link: BANK_OF_BARODA_SHARE_PRICE, text: 'Bank Of Baroda Share Price' },
];

export const popularStocks3 = [
  { link: SBI_SHARE_PRICE, text: 'SBI Share Price' },
  { link: LIC_SHARE_PRICE, text: 'LIC Share Price' },
  { link: PNB_SHARE_PRICE, text: 'PNB Share Price' },
  { link: ADANI_GREEN_SHARE_PRICE, text: 'Adani Green share price' },
  { link: IRFC_SHARE_PRICE, text: 'IRFC Share Price' },
  { link: HFCL_SHARE_PRICE, text: 'HFCL Share Price' },
  { link: HAPPIEST_MINDS_SHARE_PRICE, text: 'Happiest Minds Share Price' },
  { link: IOC_SHARE_PRICE, text: 'IOC Share Price' },
  { link: CANARA_BANK_SHARE_PRICE, text: 'Canara Bank Share Price' },
  { link: PAYTM_SHARE_PRICE, text: 'Paytm Share Price' },
  { link: NALCO_SHARE_PRICE, text: 'Nalco Share Price' },
  { link: JSW_STEEL_SHARE_PRICE, text: 'JSW Steel Share Price' },
  { link: HINDALCO_SHARE_PRICE, text: 'Hindalco Share Price' },
  { link: IFC_FIRST_BANK_SHARE_PRICE, text: 'IDFC First Bank Share Price' },
  { link: MRF_SHARE_PRICE, text: 'MRF Share Price' },
];

export const popularStocks4 = [
  { link: RELIANCE_SHARE_PRICE, text: 'Reliance Share Price' },
  { link: ADANI_POWER_SHARE_PRICE, text: 'Adani Power Share Price' },
  { link: TATA_STEEL_SHARE_PRICE, text: 'Tata Steel Share Price' },
  { link: SAIL_SHARE_PRICE, text: 'SAIL Share Price' },
  { link: ZOMATO_SHARE_PRICE, text: 'Zomato Share Price' },
  { link: BPCL_SHARE_PRICE, text: 'BPCL Share Price' },
  { link: SBI_CARD_SHARE_PRICE, text: 'SBI Card Share Price' },
  { link: ICICI_BANK_SHARE_PRICE, text: 'Icici Bank Share Price' },
  { link: COAL_INDIA_SHARE_PRICE, text: 'Coal India Share Price' },
  { link: HUL_SHARE_PRICE, text: 'HUL Share Price' },
  { link: INDUSIND_BANK_SHARE_PRICE, text: 'Indusind Bank Share Price' },
  { link: SUBEX_SHARE_PRICE, text: 'Subex Share Price' },
  { link: L_AND_T_SHARE_PRICE, text: 'L&T Share Price' },
  { link: BANDHAN_BANK_SHARE_PRICE, text: 'Bandhan Bank Share Price' },
];
