import { Fragment, FunctionComponent, useEffect, useState } from 'react';
import {
  UpstoxLogo,
  View,
  Text,
  Link,
  Button,
  CloseIcon,
  SearchSimpleIcon,
} from '@upstox/upstox-ui';
import * as css from './Header.style';
import ResponsiveMenu from './ResponsiveMenu/Responsive';
import NavMenu from './NavMenu';
import {
  tools,
  helpCenter,
  resourcesData,
  tools2,
  learningCenter,
  learningCenter2,
  investData,
  investTools,
  tradeData,
  tools3,
  aboutUs,
} from './constants';
import { getOnboardingUrl, getSignInUrl } from '../../helpers';
import SearchBox from '../SeachBox/SearchBox';
import { isStorageAvailable } from '../../common/utils/utils';
import UpstoxInvestLogoBlue from '../../../public/upstox-logo-primary-invest.svg';
import { ROUTES } from 'src/constants';

const Header: FunctionComponent = () => {
  const [onboardingUrl, setOnboardingUrl] = useState<string>(
    ROUTES.ONBOARDING_ROUTE,
  );
  const [showSearchBox, setShowSearchBox] = useState(true);
  useEffect(() => {
    setOnboardingUrl(getOnboardingUrl());
  }, []);

  const [clas, setClass] = useState('');
  const [showDrawer, setShowDrawer] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    setShowSearchBox(isStorageAvailable());
  }, []);

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  const addClass = () => {
    setClass('result_hover');
  };

  const removeClass = () => {
    setClass('');
  };

  return (
    <Fragment>
      <View css={css.headerWrapper}>
        <View className="mobileHeader">
          <View alignItems="center">
            <Link href={`${process.env.BASE_URL}/`} className="d-flex">
              <img src={UpstoxInvestLogoBlue} alt="Upstox Logo" />
            </Link>
          </View>
          <View alignItems="center">
            {showSearchBox && (
              <View margin={[0, 32, 0, 0]}>
                <View>
                  <SearchSimpleIcon
                    width="20"
                    height="20"
                    onClick={() => setShowSearch(!showSearch)}
                  />
                </View>
                {showSearch && (
                  <View css={css.mobileSearch} alignItems="center">
                    <SearchBox />
                    <CloseIcon
                      size="large"
                      onClick={() => setShowSearch(!showSearch)}
                    />
                  </View>
                )}
              </View>
            )}
            <Text as="span" className={'hamburger-bars'} onClick={toggleDrawer}>
              {' '}
            </Text>
            <ResponsiveMenu
              showDrawer={showDrawer}
              toggleDrawer={toggleDrawer}
            />
          </View>
        </View>

        <View
          className={`${'navigation-container'} ${clas}`}
          onMouseLeave={removeClass}
        >
          <View className={'main-navigation full-width'}>
            <View alignItems="center">
              <View className={'logo'}>
                <Link href={`${process.env.BASE_URL}/`}>
                  {clas === 'result_hover' ? (
                    <View css={css.logoWhite}>
                      <UpstoxLogo width="77" height="24" />
                    </View>
                  ) : (
                    <View>
                      <img src="" alt="Upstox Logo" />
                    </View>
                  )}
                </Link>
              </View>

              <nav className={'links'}>
                <ul>
                  <li
                    onMouseOver={addClass}
                    onMouseLeave={removeClass}
                    className="product-tools"
                  >
                    <a
                      href="https://upstox.com/invest-in-share-market/"
                      className="nav-link"
                    >
                      <Text color="text.1">Invest</Text>
                    </a>
                    <ul className={'sub-menu'}>
                      <NavMenu menuTitle="Invest" navMenuItems={investData} />
                      <NavMenu menuTitle="Tools" navMenuItems={investTools} />
                    </ul>
                  </li>
                  <li
                    onMouseOver={addClass}
                    onMouseLeave={removeClass}
                    className="product-tools"
                  >
                    <a
                      href="https://upstox.com/trade-in-futures-and-options/"
                      className="nav-link"
                    >
                      <Text color="text.1">Trade</Text>
                    </a>
                    <ul className={'sub-menu'}>
                      <NavMenu menuTitle="Trade" navMenuItems={tradeData} />
                      <NavMenu menuTitle="Tools" navMenuItems={tools} />
                      <NavMenu menuTitle="" navMenuItems={tools2} />
                      <NavMenu menuTitle="" navMenuItems={tools3} />
                    </ul>
                  </li>
                  <li
                    onMouseOver={addClass}
                    onMouseLeave={removeClass}
                    className="product-tools"
                  >
                    <a href="https://upstox.com/uplearn/" className="nav-link">
                      <Text size="large" color="text.1">
                        Learn
                      </Text>
                    </a>
                    <ul className={'sub-menu'}>
                      <NavMenu
                        menuTitle="Learning Center"
                        navMenuItems={learningCenter}
                      />
                      <NavMenu menuTitle={''} navMenuItems={learningCenter2} />
                      <NavMenu
                        menuTitle="Help Center"
                        navMenuItems={helpCenter}
                      />
                      <NavMenu
                        menuTitle="Resources"
                        navMenuItems={resourcesData}
                      />
                    </ul>
                  </li>
                  <li
                    onMouseOver={addClass}
                    onMouseLeave={removeClass}
                    className="product-tools"
                  >
                    <a href="https://upstox.com/about/" className="nav-link">
                      <Text color="text.1">About us</Text>
                    </a>
                    <ul className={'sub-menu'}>
                      <NavMenu menuTitle="About Us" navMenuItems={aboutUs} />
                    </ul>
                  </li>
                  <li>
                    <a href="https://upstox.com/become-sub-broker/">
                      <Text size="large" color="text.1">
                        Partner with us
                      </Text>
                    </a>
                  </li>
                  <li>
                    <a href="https://upstox.com/brokerage-charges/">
                      <Text size="large" color="text.1">
                        Pricing
                      </Text>
                    </a>
                  </li>
                </ul>
              </nav>
            </View>

            <View alignItems="center">
              {showSearchBox && <SearchBox />}
              <View margin={[0, 'medium', 0, 'medium']}>
                <a href={onboardingUrl}>
                  <Button
                    size="small"
                    variant="primary"
                    className="nav-btn-link"
                  >
                    Create account
                  </Button>
                </a>
              </View>
              <View css={css.signIn}>
                <Button
                  size="small"
                  as="a"
                  variant="secondary"
                  className="nav-btn-link-default"
                  onClick={() => window.open(getSignInUrl(), '_self')}
                >
                  Sign in
                </Button>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Fragment>
  );
};

export default Header;
