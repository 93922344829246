import React, { FunctionComponent } from 'react';
import Menu from '../Menu';
import { servicesData } from './constants';

const Services: FunctionComponent = () => {
  return (
    <section>
      <Menu menuTitle="Services" menuItems={servicesData} />
    </section>
  );
};

Services.propTypes = {};

export default Services;
