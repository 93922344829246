import React, { FunctionComponent } from 'react';
import Menu from '../Menu';
import { socialData } from './constants';

const Social: FunctionComponent = () => {
  return (
    <section>
      <Menu
        menuTitle="Social"
        menuItems={socialData}
        className={'social-links'}
        rel={'noreferrer'}
      />
    </section>
  );
};

Social.propTypes = {};

export default Social;
