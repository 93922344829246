import {
  AXIS_NET_BANKING_URL,
  BANKERS_CHEQUE_URL,
  BOI_NET_BANKING_URL,
  CHEQUE_NUMBER_URL,
  HDFC_NET_BANKING_URL,
  IMPS_FULL_FORM_URL,
  PNB_SMS_BANKING_URL,
  UNION_NET_BANKING_URL,
  SBI_NET_BANKING_URL,
  CBI_NET_BANKING_URL,
  NET_BANKING_URL,
  AXIS_BANK_NET_BANKING_URL,
  CANARA_BANK_NET_BANKING_URL,
  AXIS_BANK_CORPORATE_NET_BANKING_URL,
  RTGS_TIMINGS_URL,
  DCB_BANK_RTGS_FORM,
  PNB_NET_BANKING_URL,
  BOB_NET_BANKING_URL,
  ICICI_CORP_NET_BANKING_URL,
  RTGS_FULL_FORM_URL,
  SBI_MINI_STATEMENT_URL,
  PNB_CORPORATE_BANKING_URL,
  RBI_HOLIDAYS_URL,
  INDIAN_BANK_NET_BANKING_URL,
  SBI_CORPORATE_BANKING_URL,
  KVB_NET_BANKING_URL,
  IDBI_NET_BANKING_LOGIN_URL,
  NEFT_TIMINGS_URL,
  ALLAHABAD_BANK_NET_BANKING_URL,
  TELEGRAPHIC_TRANSFER_URL,
} from '../../Models/Actions/Footer/url-helper';

export const banking1 = [
  { link: HDFC_NET_BANKING_URL, text: 'HDFC Net Banking' },
  { link: AXIS_NET_BANKING_URL, text: 'Axis Bank Net Banking' },
  { link: UNION_NET_BANKING_URL, text: 'Union Bank Net Banking' },
  { link: BOI_NET_BANKING_URL, text: 'Bank Of India Netbanking' },
  { link: IMPS_FULL_FORM_URL, text: 'IMPS Full Form' },
  { link: CHEQUE_NUMBER_URL, text: 'Cheque Number' },
  { link: BANKERS_CHEQUE_URL, text: 'Bankers Cheque' },
  { link: PNB_SMS_BANKING_URL, text: 'PNB SMS Banking' },
];

export const banking2 = [
  { link: SBI_NET_BANKING_URL, text: 'SBI Net Banking' },
  { link: CBI_NET_BANKING_URL, text: 'CBI Net Banking' },
  { link: NET_BANKING_URL, text: 'Net Banking' },
  { link: AXIS_BANK_NET_BANKING_URL, text: 'Axis Bank Net Banking Login' },
  { link: CANARA_BANK_NET_BANKING_URL, text: 'Canara Bank Netbanking' },
  {
    link: AXIS_BANK_CORPORATE_NET_BANKING_URL,
    text: 'Axis Bank Corporate Net Banking',
  },
  { link: RTGS_TIMINGS_URL, text: 'Rtgs Timings' },
  { link: DCB_BANK_RTGS_FORM, text: 'Dcb Bank Rtgs Form' },
];

export const banking3 = [
  { link: PNB_NET_BANKING_URL, text: 'PNB Net Banking' },
  { link: BOB_NET_BANKING_URL, text: 'Bank Of Baroda Netbanking' },
  { link: ICICI_CORP_NET_BANKING_URL, text: 'ICICI Corporate Net Banking' },
  { link: RTGS_FULL_FORM_URL, text: 'RTGS Full Form' },
  { link: SBI_MINI_STATEMENT_URL, text: 'SBI Mini Statement' },
  { link: PNB_CORPORATE_BANKING_URL, text: 'Pnb Corporate Banking' },
  { link: RBI_HOLIDAYS_URL, text: 'RBI Holidays' },
];

export const banking4 = [
  { link: INDIAN_BANK_NET_BANKING_URL, text: 'Indian Bank Netbanking' },
  { link: SBI_CORPORATE_BANKING_URL, text: 'SBI Corporate Banking' },
  { link: KVB_NET_BANKING_URL, text: 'KVB Net Banking' },
  { link: IDBI_NET_BANKING_LOGIN_URL, text: 'IDBI Bank Net Banking Login' },
  { link: NEFT_TIMINGS_URL, text: 'Neft Timings' },
  { link: ALLAHABAD_BANK_NET_BANKING_URL, text: 'Allahabad Bank Netbanking' },
  { link: TELEGRAPHIC_TRANSFER_URL, text: 'Telegraphic Transfer' },
];
