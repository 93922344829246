import React, { FunctionComponent } from 'react';
import { SubMenuProps } from './typings';
import { View, Text } from '@upstox/upstox-ui';

const MenuTitle: FunctionComponent<SubMenuProps> = ({
  subMenuTitle,
  subMenuItems,
  rel,
}) => {
  return (
    <>
      {subMenuTitle && (
        <View className={'r-title'} alignItems="center">
          <Text color="background.gold" variant="bodyBold">
            {subMenuTitle}
          </Text>
        </View>
      )}
      <View as="ul" flexDirection="column">
        {subMenuItems.map((item, index) => {
          return (
            <View as="li" key={index}>
              <a href={item.link} target={item.target} rel={rel}>
                <Text color="text.white" variant="bodyBold">
                  {item.text}
                </Text>
              </a>
            </View>
          );
        })}
      </View>
    </>
  );
};

export default MenuTitle;
