import React, { FunctionComponent } from 'react';
import { NavMenuProps } from './typings';
import { View, Text } from '@upstox/upstox-ui';

const NavMenu: FunctionComponent<NavMenuProps> = ({
  menuTitle,
  navMenuItems,
  rel,
}) => {
  return (
    <li className={'sub-menu-item'}>
      <Text as="h4">{menuTitle ? menuTitle : <>&nbsp;</>}</Text>
      <View>
        <ul>
          {navMenuItems.map((data, index) => (
            <li className={'menu-item'} key={index}>
              <a href={data.link} target={data.target} rel={rel}>
                {data.text}
              </a>
            </li>
          ))}
        </ul>
      </View>
    </li>
  );
};

export default NavMenu;
