import React, { FunctionComponent } from 'react';
import { Text } from '@upstox/upstox-ui';

import UpstoxLogo from '../../images/upstox-logo-white.png';
import PlayStoreLogo from '../../images/download-playStore.png';
import AppStoreLogo from '../../images/download-appStore.png';
import {
  APPLE_APP_STORE_APP_DOWNLOAD_URL,
  GOOGLE_PLAY_STORE_APP_DOWNLOAD_URL,
} from '../../Models/Actions/Footer/url-helper';

const AppLinks: FunctionComponent = () => {
  return (
    <section>
      <div className="footer-download-app-links">
        <div className="foot-logo">
          <img src={UpstoxLogo} alt="footer-logo" />
        </div>

        <div className="text">
          <Text color="text.white" variant="meta">
            <span>
              30th Floor, Sunshine Tower, Senapati Bapat Marg, Dadar (W),
              Mumbai, Maharashtra 400013
            </span>
          </Text>
        </div>

        <div className="footer-downloadApp-logo">
          <a
            href={GOOGLE_PLAY_STORE_APP_DOWNLOAD_URL}
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <img src={PlayStoreLogo} alt="play-store" />
          </a>
          <a
            href={APPLE_APP_STORE_APP_DOWNLOAD_URL}
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <img src={AppStoreLogo} alt="apple-store" />
          </a>
        </div>
      </div>
    </section>
  );
};

AppLinks.propTypes = {};

export default AppLinks;
