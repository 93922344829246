import {
  PRO_WEB_URL,
  PRO_MOBILE_URL,
  MUTUAL_FUNDS_URL,
  BROKERAGE_CALCULATOR_URL,
  MARGIN_CALCULATOR_URL,
  MF_RETURNS_CALCULATOR_URL,
  LEARNING_CENTER_DEMAT_URL,
  LEARNING_CENTER_INTRADAY_URL,
  LEARNING_CENTER_IPO_URL,
  LEARNING_CENTER_MF_URL,
  LEARNING_CENTER_ONLINE_URL,
  LEARNING_CENTER_OPTIONS_URL,
  LEARNING_CENTER_SHAREMARKET_URL,
  LEARNING_CENTER_TRADING_URL,
  HELP_CENTER_AO,
  HELP_CENTER_POSTTRADE,
  HELP_CENTER_HELPDESK,
  RESOURCES_KNOWLEDGE,
  TRADING_GLOSSARY_URL,
  MARKET_TALK_URL,
  SIP_CALCULATOR_URL,
  LUMPSUM_CALCULATOR_URL,
  NPV_URL,
  FUTURE_VALUE_URL,
  ELSS_CALCULATOR_URL,
  ABOUT_US_URL,
  LIFE_AT_UPSTOX_URL,
  TRADE_URL,
  ALL_CALCULATOR_URL,
  SWP_CALCULATOR_URL,
  OPTION_VALUE_URL,
  NPS_CALCULATOR_URL,
  INVEST_URL,
  NEW_IPO_URL,
  INVEST_CHART_URL,
  HOLIDAY_CALENDAR_URL,
} from '../Models/Actions/Footer/url-helper';

export const investData = [
  {
    text: 'Stocks',
    link: INVEST_URL,
  },
  {
    text: 'Mutual Funds',
    link: MUTUAL_FUNDS_URL,
  },
  {
    text: 'IPOs',
    link: NEW_IPO_URL,
  },
];
export const investTools = [
  {
    text: 'MF Returns Calculator',
    link: MF_RETURNS_CALCULATOR_URL,
  },
  {
    text: 'SIP Calculator',
    link: SIP_CALCULATOR_URL,
  },
  {
    text: 'ELSS Calculator',
    link: ELSS_CALCULATOR_URL,
  },

  {
    text: 'Brokerage Calculator',
    link: BROKERAGE_CALCULATOR_URL,
  },
];

export const tradeData = [
  {
    text: 'Futures & Options',
    link: TRADE_URL,
  },
  {
    text: 'Commodities',
    link: TRADE_URL,
  },
  {
    text: 'Currency',
    link: TRADE_URL,
  },
];
export const tools = [
  {
    text: 'Brokerage Calculator',
    link: BROKERAGE_CALCULATOR_URL,
  },
  {
    text: 'Span Calculator',
    link: MARGIN_CALCULATOR_URL,
  },
  {
    text: 'NPV Calculator',
    link: NPV_URL,
  },
  {
    text: 'Lumpsum Calculator',
    link: LUMPSUM_CALCULATOR_URL,
  },
];
export const tools2 = [
  {
    text: 'NPS Calculator',
    link: NPS_CALCULATOR_URL,
  },
  {
    text: 'Option Value Calculator',
    link: OPTION_VALUE_URL,
  },
  {
    text: 'SWP Calculator',
    link: SWP_CALCULATOR_URL,
  },
  {
    text: 'Future Value Calculator',
    link: FUTURE_VALUE_URL,
  },
];
export const tools3 = [
  {
    text: 'View all Calculators',
    link: ALL_CALCULATOR_URL,
  },
];

export const aboutUs = [
  {
    text: 'Our Story',
    link: ABOUT_US_URL,
  },
  {
    text: 'Life at upstox',
    link: LIFE_AT_UPSTOX_URL,
  },
];

export const productData = [
  {
    text: 'Web/Desktop',
    link: PRO_WEB_URL,
  },
  {
    text: 'Mobile Apps',
    link: PRO_MOBILE_URL,
  },
];

export const learningCenter = [
  {
    text: 'Demat Account',
    link: LEARNING_CENTER_DEMAT_URL,
  },
  {
    text: 'Trading Account',
    link: LEARNING_CENTER_TRADING_URL,
  },
  {
    text: 'Online Trading',
    link: LEARNING_CENTER_ONLINE_URL,
  },
  {
    text: 'Intraday Trading',
    link: LEARNING_CENTER_INTRADAY_URL,
  },
];

export const learningCenter2 = [
  {
    text: 'Futures and Options',
    link: LEARNING_CENTER_OPTIONS_URL,
  },
  {
    text: 'Mutual Funds',
    link: LEARNING_CENTER_MF_URL,
  },
  {
    text: 'Share Market',
    link: LEARNING_CENTER_SHAREMARKET_URL,
  },
  {
    text: 'IPO',
    link: LEARNING_CENTER_IPO_URL,
  },
];
export const helpCenter = [
  {
    text: 'Account Opening',
    link: HELP_CENTER_AO,
  },
  {
    text: 'Post Trade Actions',
    link: HELP_CENTER_POSTTRADE,
  },
  {
    text: 'Submit Helpdesk Ticket',
    link: HELP_CENTER_HELPDESK,
  },
];
export const resourcesData = [
  {
    text: 'Trading Glossary',
    link: TRADING_GLOSSARY_URL,
  },
  {
    text: 'Knowledge Base',
    link: RESOURCES_KNOWLEDGE,
  },
  {
    text: 'Market Talk',
    link: MARKET_TALK_URL,
  },
  {
    text: 'Investment Charter',
    link: INVEST_CHART_URL,
  },
  {
    text: 'Stock Market Holidays Calendar 2022',
    link: HOLIDAY_CALENDAR_URL,
  },
];
