//@ts-nocheck
import React, { FunctionComponent } from 'react';
import Menu from '../Menu';
import { Text } from '@upstox/upstox-ui';
import {
  savingAccount1,
  savingAccount2,
  savingAccount3,
  savingAccount4,
} from './constants';

const SavingAccount: FunctionComponent = () => {
  return (
    <section className={'footer-tab-data'}>
      <div className="row">
        <div className="col">
          <p className={'footer-menu-title'}>
            <Text>{'Saving Account'}</Text>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-3 mb35">
          <Menu
            menuTitle=""
            menuItems={savingAccount1}
            className="menuContainer"
          />
        </div>
        <div className="col-3 mb35">
          <Menu
            menuTitle=""
            menuItems={savingAccount2}
            className="menuContainer"
          />
        </div>

        <div className="col-3 mb35">
          <Menu
            menuTitle=""
            menuItems={savingAccount3}
            className="menuContainer"
          />
        </div>

        <div className="col-3 mb35">
          <Menu
            menuTitle=""
            menuItems={savingAccount4}
            className="menuContainer"
          />
        </div>
      </div>
    </section>
  );
};

SavingAccount.propTypes = {};

export default SavingAccount;
