import {
  IPO_ALLOTMENT_STATUS_URL,
  IPO_SUBSCRIPTION_URL,
  DPO_URL,
  IPO_LEARN_URL,
  HOW_TO_APPLY_FOR_IPO_URL,
  CUT_OFF_PRICE_IN_IPO_URL,
  BENEFITS_OF_IPO_URL,
  DIRECT_LISTING_VS_IPO_URL,
  FLIPPING_URL,
  SME_IPO_URL,
  IPO_CALENDER_URL,
  IMPORTANCE_OF_CAPITAL_BUDGETING_URL,
  PRICE_BAND_URL,
  IPO_PRICE_URL,
  IPO_LOCK_URL,
  FIXED_PRICE_ISSUE_URL,
  GREY_MARKET_PREMIUM_PREMIUM_URL,
  FACE_VALUE_URL,
  GREENSHOE_OPTION_URL,
  INVESTING_IN_IPO_URL,
  LOT_SIZE_URL,
  PRE_IPO_URL,
  IPO_REQUIREMENT_URL,
  FPO_URL,
  IPO_LISTING_URL,
  OFS_URL,
  ISSUE_PRUCE_URL,
  OVERSUBSCRIPTION_URL,
  LUNCH_IPO_URL,
  BOOK_BUILDING_IPO_URL,
} from '../../Models/Actions/Footer/url-helper';

export const ipoLearn1 = [
  { link: IPO_ALLOTMENT_STATUS_URL, text: 'IPO allotment status' },
  { link: IPO_SUBSCRIPTION_URL, text: 'Ipo Subscription' },
  { link: DPO_URL, text: 'DPO' },
  { link: IPO_LEARN_URL, text: 'IPO Cycle' },
  { link: HOW_TO_APPLY_FOR_IPO_URL, text: 'how to apply for ipo' },
  { link: CUT_OFF_PRICE_IN_IPO_URL, text: 'Cut off price in IPO' },
  { link: BENEFITS_OF_IPO_URL, text: 'Benefits of IPO' },
  { link: DIRECT_LISTING_VS_IPO_URL, text: 'direct listing vs ipo' },
];

export const ipoLearn2 = [
  { link: FLIPPING_URL, text: 'Flipping' },
  { link: SME_IPO_URL, text: 'SME IPO' },
  { link: IPO_CALENDER_URL, text: 'IPO Calender' },
  {
    link: IMPORTANCE_OF_CAPITAL_BUDGETING_URL,
    text: 'Importance of capital budgeting',
  },
  { link: PRICE_BAND_URL, text: 'Price Band' },
  { link: IPO_PRICE_URL, text: 'IPO Price' },
  { link: IPO_LOCK_URL, text: 'ipo lock in period' },
  { link: FIXED_PRICE_ISSUE_URL, text: 'Fixed Price issue' },
];

export const ipoLearn3 = [
  { link: GREY_MARKET_PREMIUM_PREMIUM_URL, text: 'grey market premium' },
  { link: FACE_VALUE_URL, text: 'Face Value' },
  { link: GREENSHOE_OPTION_URL, text: 'Greenshoe Option' },
  { link: INVESTING_IN_IPO_URL, text: 'Investing in IPOs' },
  { link: LOT_SIZE_URL, text: 'Lot Size' },
  { link: PRE_IPO_URL, text: 'Pre IPO' },
  { link: IPO_REQUIREMENT_URL, text: 'IPO Requirement' },
];

export const ipoLearn4 = [
  { link: FPO_URL, text: 'FPO' },
  { link: IPO_LISTING_URL, text: 'IPO Listing' },
  { link: OFS_URL, text: 'OFS' },
  { link: ISSUE_PRUCE_URL, text: 'Issue Price' },
  { link: OVERSUBSCRIPTION_URL, text: 'Oversubscription' },
  { link: LUNCH_IPO_URL, text: 'Launch IPO' },
  { link: BOOK_BUILDING_IPO_URL, text: 'book building ipo' },
];
