import {
  PRO_WEB_URL,
  PRO_MOBILE_URL,
  MUTUAL_FUNDS_URL,
  DEVELOPER_URL,
  IPOS_URL,
  FUTURES_AND_OPTIONS_URL,
  STOCKS_URL,
  INDICES_URL,
  UPSTOX_X_TRADING_VIEW_URL,
} from '../../Models/Actions/Footer/url-helper';

export const productsData = [
  {
    text: 'Pro Web',
    link: PRO_WEB_URL,
  },
  {
    text: 'Pro Mobile',
    link: PRO_MOBILE_URL,
  },
  {
    text: 'Mutual Funds',
    link: MUTUAL_FUNDS_URL,
  },
  {
    text: 'IPOs',
    link: IPOS_URL,
  },
  {
    text: 'Futures And Options',
    link: FUTURES_AND_OPTIONS_URL,
  },
  {
    text: 'Stocks',
    link: STOCKS_URL,
  },
  {
    text: 'Indices',
    link: INDICES_URL,
  },
  {
    text: 'Developer',
    link: DEVELOPER_URL,
  },
  {
    text: 'Upstox x TradingView',
    link: UPSTOX_X_TRADING_VIEW_URL,
  },
];
