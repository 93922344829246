import { spacing, color } from '@upstox/upstox-ui';
import felaPluginExtend from 'fela-plugin-extend';

export const wrapper: CSSFunction = () => ({
  position: 'fixed',
  width: '100%',
  bottom: 0,
});

export const cardWrapper: CSSFunction = ({ theme }) => ({
  padding: spacing(theme, 12),
  width: '100%',
  backgroundColor: color(theme, 'background.aqua'),
});

export const marginButton: CSSFunction = ({ theme }) => ({
  position: 'fixed',
  top: '310px',
  right: '-80px',
  backgroundColor: color(theme, '#5A298B'),
  borderRadius: '8px 8px 0px 0px',
  transform: 'rotate(270deg)',
  '& a': {
    height: '32px',
    width: '192px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#FFFFFF',
    fontFamily: 'Messina Sans',
  },
});
