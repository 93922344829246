import {
  DOC_REQUIREMENT_DEMAT_URL,
  DEMAT_AO_URL,
  DEMAT_TRADING_DIFF_URL,
  DOC_REQUIREMENT_TRADING_URL,
  TRADING_AO_URL,
  BENEFITS_TRADING_ACCOUNT_URL,
  ONLINE_TRADING_BENEFITS_URL,
  ONLINE_OFFLINE_TRADING_URL,
  FUNDAMENTAL_ANALYSIS_URL,
  INTRADAY_TRADING_URL,
  INTRADAY_DELIVERY_URL,
  INTRADAY_TIPS_URL,
  FUTURES_TRADING_URL,
  TRADING_BENEFITS_FUTURES_URL,
  MF_BENEFITS_URL,
  NAV_URL,
  ELSS_URL,
  STOCK_MARKET_GUIDE_URL,
  NSE_BSE_URL,
  EQUITY_BENIFITS_URL,
  IPO_URL,
  IPO_ADVANTAGES_URL,
  IPO_PROCESS_URL,
  TRADING_BENEFITS_WITH_BROKERS_URL,
  DISCOUNT_BROKING_URL,
  BROKERS_DIFF_URL,
} from '../../Models/Actions/Footer/url-helper';

export const dematAccountGuide = [
  {
    link: DOC_REQUIREMENT_DEMAT_URL,
    text: 'Documents required for demat account',
  },
  {
    link: DEMAT_AO_URL,
    text: 'Demat account opening procedure',
  },
  {
    link: DEMAT_TRADING_DIFF_URL,
    text: 'Difference between demat account and trading account',
  },
];

export const tradingAccountGuide = [
  {
    link: DOC_REQUIREMENT_TRADING_URL,
    text: 'Documents required to open a trading account',
  },
  {
    link: TRADING_AO_URL,
    text: 'Trading account opening procedure',
  },
  {
    link: BENEFITS_TRADING_ACCOUNT_URL,
    text: 'Features and benefits of a trading account',
  },
];

export const onlineTradingGuide = [
  {
    link: ONLINE_TRADING_BENEFITS_URL,
    text: 'Benefits of online trading',
  },
  {
    link: ONLINE_OFFLINE_TRADING_URL,
    text: 'Difference between online and offline trading',
  },
  {
    link: FUNDAMENTAL_ANALYSIS_URL,
    text: 'What is fundamental analysis and how to do it?',
  },
];

export const intradayTradingGuide = [
  {
    link: INTRADAY_TRADING_URL,
    text: 'What is Intraday Trading?',
  },
  {
    link: INTRADAY_DELIVERY_URL,
    text: 'Difference between intraday and delivery trading',
  },
  {
    link: INTRADAY_TIPS_URL,
    text: 'Intraday trading tips and tricks',
  },
];

export const futuresAndOptionsGuide = [
  {
    link: FUTURES_TRADING_URL,
    text: 'What is futures trading?',
  },
  {
    link: TRADING_BENEFITS_FUTURES_URL,
    text: 'Benefits of trading in futures',
  },
];

export const mutualFundsGuide = [
  {
    link: MF_BENEFITS_URL,
    text: 'Benefits of investing in mutual funds',
  },
  {
    link: NAV_URL,
    text: 'What is NAV (Net Asset Value)?',
  },
  {
    link: ELSS_URL,
    text: 'What is ELSS and how to invest in ELSS?',
  },
];

export const shareMarketGuide = [
  {
    link: STOCK_MARKET_GUIDE_URL,
    text: 'Stock market guide for beginners',
  },
  {
    link: NSE_BSE_URL,
    text: 'What is NSE and BSE?',
  },
  {
    link: EQUITY_BENIFITS_URL,
    text: 'Benefits of equity investment',
  },
];

export const IPOGuide = [
  {
    link: IPO_URL,
    text: 'How to invest in an IPO?',
  },
  {
    link: IPO_ADVANTAGES_URL,
    text: 'What are the advantages of an IPO?',
  },
  {
    link: IPO_PROCESS_URL,
    text: 'How does an IPO work?',
  },
];

export const StockBrokerGuide = [
  {
    link: TRADING_BENEFITS_WITH_BROKERS_URL,
    text: 'Benefits of trading with discount brokers',
  },
  {
    link: DISCOUNT_BROKING_URL,
    text: 'Why discount broking is popular in India',
  },
  {
    link: BROKERS_DIFF_URL,
    text: 'Differences between traditional brokers and discount brokers',
  },
];
