export const wrapper: CSSFunction = ({ theme }) => ({
  // minWidth: '318px',
  width: '100%',

  '@media (min-width: 1024px)': {
    width: '35%',
  },

  '@media (min-width: 1200px)': {
    width: '25% !important',
  },
});
