import React, { FunctionComponent } from 'react';
import Menu from '../Menu';
import {
  dematAccountGuide,
  tradingAccountGuide,
  onlineTradingGuide,
  intradayTradingGuide,
  futuresAndOptionsGuide,
  mutualFundsGuide,
  shareMarketGuide,
  IPOGuide,
  NRITradingGuide,
  StockBrokerGuide,
} from './constants';

const LearningCenter: FunctionComponent = () => {
  return (
    <section className={'footer-tab-data'}>
      <>
        <div className="row">
          <div className="col-4 mb35">
            <Menu
              menuTitle="Demat Account Guide"
              menuItems={dematAccountGuide}
            />
          </div>

          <div className="col-4 mb35">
            <Menu
              menuTitle="Trading Account Guide"
              menuItems={tradingAccountGuide}
            />
          </div>

          <div className="col-4 mb35">
            <Menu
              menuTitle="Online Trading Guide"
              menuItems={onlineTradingGuide}
            />
          </div>

          <div className="col-4 mb35">
            <Menu
              menuTitle="Intraday Trading Guide"
              menuItems={intradayTradingGuide}
            />
          </div>

          <div className="col-4 mb35">
            <Menu
              menuTitle="Futures and Options Guide"
              menuItems={futuresAndOptionsGuide}
            />
          </div>

          <div className="col-4 mb35">
            <Menu menuTitle="Mutual Funds Guide" menuItems={mutualFundsGuide} />
          </div>

          <div className="col-4 mb35">
            <Menu menuTitle="Share Market Guide" menuItems={shareMarketGuide} />
          </div>

          <div className="col-4 mb35">
            <Menu menuTitle="IPO Guide" menuItems={IPOGuide} />
          </div>

          {/* <div className="col-4 mb35">
          <Menu menuTitle="NRI Trading Guide" menuItems={NRITradingGuide} />
        </div> */}

          <div className="col-4 mb35">
            <Menu menuTitle="Stock Broker Guide" menuItems={StockBrokerGuide} />
          </div>
        </div>
      </>
    </section>
  );
};

LearningCenter.propTypes = {};

export default LearningCenter;
