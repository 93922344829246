import React, { FunctionComponent } from 'react';
import Menu from '../Menu';
import { productsData } from './constants';

const Products: FunctionComponent = () => {
  return (
    <section>
      <Menu menuTitle="Products" menuItems={productsData} />
    </section>
  );
};

export default Products;
