export namespace CONSTANTS {
  export const LANDING_PAGE_TITLE =
    'IPO - Upcoming IPO in India - IPO Watch - Allotment Status';

  export const LANDING_META_DESCRIPTION =
    'IPO Watch - Find Latest & Upcoming IPOs List 2022. Know about IPOs Timeline, allotment status, Reviews, listing details and more at Upstox.com. Apply Online Today.';
}

export namespace ROUTES {
  export const ONBOARDING_ROUTE = 'https://upstox.com/open-demat-account/';
}
