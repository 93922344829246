import {
  KOTAK_NET_BANKING_URL,
  DEMONETISATION_URL,
  HDFC_MOBILE_BANKING_URL,
  IRCTC_EWALLET_URL,
  AXIS_MOBILE_BANKING_URL,
  CANARA_BANK_SAVINGS_ACCOUNT_URL,
  BANDHAN_BANK_SAVINGS_ACCOUNTS_URL,
  PNB_VIDYARTHI_SF_ACCOUNT_URL,
  CANCELLED_CHEQUE_URL,
  CITI_BANK_NET_BANKING_URL,
  SBI_SAVINGS_ACCOUNTS_URL,
  HDFC_BANK_SAVINGS_ACCOUNTS_URL,
  PNB_SAVINGS_ACCOUNTS_URL,
  FEDERAL_BANK_SAVINGS_ACCOUNTS_URL,
  HDFC_SMS_BANKING_URL,
  CBI_SAVING_ACCOUNTS_URL,
  KOTAK_811_URL,
  SAVINGS_ACCOUNTS_URL,
  AXIS_BANK_SAVINGS_ACCOUNTS_URL,
  SBI_SALARY_ACCOUNT_URL,
  BOB_SAVINGS_ACCOUNT_URL,
  CITI_BANK_SAVINGS_ACCOUNTS_URL,
  SBI_IMPS_URL,
  FEDERAL_BANK_NET_BANKING_URL,
  SAVING_ACCOUNT_INTEREST_RATE_URL,
  AXIS_BANK_SALARY_ACCOUNT_URL,
  UNION_BANK_OF_INDIA_SAVINGS_ACCOUNTS_URL,
  INDUSIND_BANK_SAVING_ACCOUNT_URL,
  RBL_BANK_SAVINGS_ACCOUNT_URL,
  INDIAN_BANK_SB_ACCOUNT_URL,
} from '../../Models/Actions/Footer/url-helper';

export const savingAccount1 = [
  { link: KOTAK_NET_BANKING_URL, text: 'Kotak Net Banking' },
  { link: DEMONETISATION_URL, text: 'Demonetisation' },
  { link: HDFC_MOBILE_BANKING_URL, text: 'HDFC Mobile Banking' },
  { link: IRCTC_EWALLET_URL, text: 'Irctc Ewallet' },
  { link: AXIS_MOBILE_BANKING_URL, text: 'Axis Mobile Banking' },
  {
    link: CANARA_BANK_SAVINGS_ACCOUNT_URL,
    text: 'Canara Bank Savings Account',
  },
  {
    link: BANDHAN_BANK_SAVINGS_ACCOUNTS_URL,
    text: 'Bandhan Bank Savings Account',
  },
  { link: PNB_VIDYARTHI_SF_ACCOUNT_URL, text: 'PNB Vidyarthi Sf Account' },
];

export const savingAccount2 = [
  { link: CANCELLED_CHEQUE_URL, text: 'Cancelled Cheque' },
  { link: CITI_BANK_NET_BANKING_URL, text: 'Citibank Net Banking' },
  { link: SBI_SAVINGS_ACCOUNTS_URL, text: 'SBI Savings Account' },
  { link: HDFC_BANK_SAVINGS_ACCOUNTS_URL, text: 'HDFC Bank Savings Account' },
  { link: PNB_SAVINGS_ACCOUNTS_URL, text: 'PNB Savings Account' },
  {
    link: FEDERAL_BANK_SAVINGS_ACCOUNTS_URL,
    text: 'Federal Bank Savings Account',
  },
  { link: HDFC_SMS_BANKING_URL, text: 'HDFC Sms Banking' },
  {
    link: CBI_SAVING_ACCOUNTS_URL,
    text: 'Central Bank Of India Savings Account',
  },
];

export const savingAccount3 = [
  { link: KOTAK_811_URL, text: 'Kotak 811' },
  { link: SAVINGS_ACCOUNTS_URL, text: 'Savings Account' },
  { link: AXIS_BANK_SAVINGS_ACCOUNTS_URL, text: 'Axis Bank Savings Account' },
  { link: SBI_SALARY_ACCOUNT_URL, text: 'SBI Salary Account' },
  { link: BOB_SAVINGS_ACCOUNT_URL, text: 'Bank Of Baroda Savings Account' },
  { link: CITI_BANK_SAVINGS_ACCOUNTS_URL, text: 'Citibank Savings Account' },
  { link: SBI_IMPS_URL, text: 'SBI IMPS' },
];

export const savingAccount4 = [
  { link: FEDERAL_BANK_NET_BANKING_URL, text: 'Federal Bank Net Banking' },
  {
    link: SAVING_ACCOUNT_INTEREST_RATE_URL,
    text: 'Savings Account Interest Rates',
  },
  { link: AXIS_BANK_SALARY_ACCOUNT_URL, text: 'Axis Bank Salary Account' },
  {
    link: UNION_BANK_OF_INDIA_SAVINGS_ACCOUNTS_URL,
    text: 'Union Bank Of India Savings Account',
  },
  {
    link: INDUSIND_BANK_SAVING_ACCOUNT_URL,
    text: 'Indusind Bank Saving Account',
  },
  { link: RBL_BANK_SAVINGS_ACCOUNT_URL, text: 'RBL Bank Savings Account' },
  { link: INDIAN_BANK_SB_ACCOUNT_URL, text: 'Indian Bank Sb Account' },
];
