import React, { FunctionComponent } from 'react';
import { Text } from '@upstox/upstox-ui';
import Menu from '../Menu';
import {
  moreDataList1,
  moreDataList2,
  moreDataList3,
  moreDataList4,
} from './constants';

const More: FunctionComponent = () => {
  return (
    <section className={'footer-tab-data'}>
      <div className="row">
        <div className="col-3" style={{ marginBottom: '-25px' }}>
          <p className={'footer-menu-title'}>
            <Text>{'More'}</Text>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-3 mb35">
          <Menu menuTitle="" menuItems={moreDataList1} />
        </div>
        <div className="col-3 mb35">
          <Menu menuTitle="" menuItems={moreDataList2} />
        </div>
        <div className="col-3 mb35">
          <Menu menuTitle="" menuItems={moreDataList3} />
        </div>
        <div className="col-3 mb35">
          <Menu menuTitle="" menuItems={moreDataList4} />
        </div>
      </div>
    </section>
  );
};

More.propTypes = {};

export default More;
