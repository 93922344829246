import React, { FunctionComponent, useState, useEffect } from 'react';
import { UpstoxLogo, View, Text, CloseIcon } from '@upstox/upstox-ui';
import Drawer from '../../../common/components/Drawer/drawer';
import Tab from '../../Tabs/components/Tab';
import MenuTitle from '../MenuTitle';
import {
  investData,
  investTools,
  tools,
  learningCenter,
  helpCenter,
  resourcesData,
  tools2,
  learningCenter2,
  aboutUs,
  tools3,
} from '../constants';
import { getOnboardingUrl, getSignInUrl } from '../../../helpers';
import { ROUTES } from 'src/constants';

interface Props {
  showDrawer?: boolean;
  toggleDrawer?: () => void;
  CloseButton?: () => void;
  children?: React.ReactNode;
}

const ResponsiveMenu: FunctionComponent<Props> = ({
  showDrawer,
  toggleDrawer,
}) => {
  const [tabId, setTabId] = useState(-1);
  const [onboardingUrl, setOnboardingUrl] = useState<string>(
    ROUTES.ONBOARDING_ROUTE,
  );
  const [signInUrl, setSignUrl] = useState(process.env.LOGIN_URL);

  useEffect(() => {
    setOnboardingUrl(getOnboardingUrl());
    setSignUrl(getSignInUrl());
  }, []);

  const tabData = [
    {
      title: 'Invest',
    },
    {
      title: 'Trade',
    },
    {
      title: 'Learn',
    },
    {
      title: 'About us',
    },
  ];

  const handleTabChange = (id: number) => {
    if (tabId === -1) {
      setTabId(id);
    } else {
      setTabId(-1);
    }
  };

  return (
    <>
      {showDrawer && (
        <Drawer
          withCloseButton={false}
          style={{ height: '100%', borderRadius: 0 }}
          offset={0}
          zIndex={5}
        >
          <View className={`mobile-menu`}>
            <View flexDirection="column" className="MobileMenuItems full-width">
              <View as="ul" className={'responsive-header'}>
                <View as="li">
                  <a
                    href={`${process.env.BASE_URL}/ipo-initial-public-offering`}
                  >
                    <UpstoxLogo width="77" height="24" />
                  </a>{' '}
                </View>
                <View as="li">
                  <CloseIcon
                    size="large"
                    color="text.3"
                    onClick={toggleDrawer}
                  />
                </View>
              </View>

              <View flexDirection="column" className={'r-topmenu'}>
                <View as="ul" flexDirection="column" className={'menu-items'}>
                  <View as="li">
                    <a href={onboardingUrl} style={{ fontWeight: 600 }}>
                      <Text color="text.white" variant="bodyBold">
                        Create Account
                      </Text>
                    </a>
                  </View>
                  <View as="li">
                    <a href={signInUrl} style={{ fontWeight: 600 }}>
                      <Text color="text.white" variant="bodyBold">
                        Sign in
                      </Text>
                    </a>
                  </View>
                </View>
              </View>

              <View flexDirection="column">
                {tabData.map((tab, index) => {
                  return (
                    <View key={index} flexDirection="column">
                      <Tab
                        id={index}
                        title={
                          <Text color="text.white" variant="bodyBold">
                            {tab.title}
                          </Text>
                        }
                        key={index}
                        onTabSelect={handleTabChange}
                        isActive={index === tabId}
                        activeTabClassName={'r-header-active'}
                        className="r-header full-width"
                      />
                      {tabId == 0 && index === 0 && (
                        <View flexDirection="column" className="r-collapse">
                          <MenuTitle
                            subMenuTitle="Invest"
                            subMenuItems={investData}
                          />
                          <MenuTitle
                            subMenuTitle="Tools"
                            subMenuItems={investTools}
                          />
                        </View>
                      )}
                      {tabId == 1 && index === 1 && (
                        <View flexDirection="column" className="r-collapse">
                          <MenuTitle
                            subMenuTitle="Trade"
                            subMenuItems={aboutUs}
                          />
                          <MenuTitle
                            subMenuTitle="Tools"
                            subMenuItems={tools}
                          />
                          <MenuTitle subMenuTitle="" subMenuItems={tools2} />
                          <MenuTitle subMenuTitle="" subMenuItems={tools3} />
                        </View>
                      )}
                      {tabId == 2 && index === 2 && (
                        <View flexDirection="column" className="r-collapse">
                          <MenuTitle
                            subMenuTitle="Learning Center"
                            subMenuItems={learningCenter}
                          />
                          <MenuTitle
                            title={false}
                            subMenuTitle={''}
                            subMenuItems={learningCenter2}
                          />
                          <MenuTitle
                            subMenuTitle="Help Center"
                            subMenuItems={helpCenter}
                          />
                          <MenuTitle
                            subMenuTitle="Resources"
                            subMenuItems={resourcesData}
                          />
                        </View>
                      )}
                      {tabId == 3 && index === 3 && (
                        <View flexDirection="column" className="r-collapse">
                          <MenuTitle
                            subMenuTitle="About us"
                            subMenuItems={aboutUs}
                          />
                        </View>
                      )}
                    </View>
                  );
                })}
                <View as="ul" flexDirection="column" className={'menu-items'}>
                  <View as="li" className="mb0">
                    <Text variant="bodyBold" color="text.white">
                      <a href="https://upstox.com/partner/">
                        <Text color="text.white" variant="bodyBold">
                          Partner with us
                        </Text>
                      </a>
                    </Text>
                  </View>
                  <View as="li">
                    <a href="https://upstox.com/brokerage-charges/">
                      <Text color="text.white" variant="bodyBold">
                        Pricing
                      </Text>
                    </a>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </Drawer>
      )}
    </>
  );
};

export default ResponsiveMenu;
